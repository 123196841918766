import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { APIController } from '../../common/API';
import * as MyIF from '../../common/Interface';
import { useAlert } from '../../provider/alert/AlertProvider';
import FlowArea from '../../components/flow/FlowArea';
import NameArea from '../../components/modelNumberSpecDetails/NameArea';
import ThreeDModelInputArea from '../../components/modelNumberSpecDetails/ThreeDModelInputArea';
import ModelConditionsArea from '../../components/modelNumberSpecDetails/ModelingConditionsArea';
import ModelingResultArea from '../../components/modelNumberSpecDetails/ModelingResultArea';
import TrialCalcInfoArea from '../../components/modelNumberSpecDetails/TrialCalcInfoArea';
import OptionArea from '../../components/modelNumberSpecDetails/OptionArea';
import PriceAreaRef from '../../components/modelNumberSpecDetails/PriceAreaRef';
import ViewerArea from '../../components/modelNumberSpecDetails/ViewerArea';
import ModalMaterialHikaku from '../../components/modelNumberSpecDetails/ModalMaterialHikaku';
import { FileController } from '../../common/FileController';
import { gavadonFuncHandle } from '../../components/viewer/Gavadon';
import { useProgress } from '../../provider/progress/ProgressProvider';
import FooterContent from '../../components/footer/Footer';
import uuid from 'react-uuid';

const useModelNumberSpecRef = () => {
  const { setAlertShow, setMessageList } = useAlert();
  const { setProgressShow } = useProgress();
  const location = useLocation();
  const navigate = useNavigate();

  // 材料比較画面　制御用
  const [isModalMateHikakuOpen, setIsModalMateHikakuOpen] = React.useState(false);
  // 3D描画情報
  const [modelViewInfo, setModelViewInfo] = React.useState<MyIF.modelViewInfo>({
    id: '',
    supportMpacKey: '',
    modelMpacKey: '',
    bucketName: '',
    rotate: { x: 0, y: 0, z: 0 },
    image: '',
  });
  // 型番
  const modelNumber: string = location.state.modelNumber;
  // ラベル名
  const [labelName, setLabelName] = React.useState<string>(location.state.labelName);
  // 3Dファイル名
  const [threeDFileName, setThreeDFileName] = React.useState<string>();
  // 造形条件
  const [selectModelCondition, setSelectModelCondition] =
    React.useState<MyIF.selectModelingConditionType>({
      modelingMaterialId: undefined,
      materialValue: '',
      methodValue: '',
      printerValue: '',
    });
  // 造形可否判定結果　1:OK 2:OK注記あり 3:NG
  const [isModelingJudge, setIsModelingJudge] = React.useState<number>(0);
  // 造形可否判定結果
  const [modelingResultList, setModelingResultList] = React.useState<MyIF.modelingResultType[]>();
  // 試算基本情報
  const [trialCalcInfo, setTrialCalcInfo] = React.useState<MyIF.trialCalcInfoType>();
  // オプション情報
  const [selectOptionInfo, setSelectOptionInfo] = React.useState<MyIF.optionInfo>({
    optionSelectList: [],
    // 指示書フラグ True:OFF　False:ON
    dirFlg: true,
    dirComment: '',
  });
  // 単価情報
  const [priceInfo, setPriceInfo] = React.useState<MyIF.priceInfo>({
    normal: {
      price: 123456789,
      date: '500',
    },
    express: {
      price: 123,
      date: '50',
    },
  });
  // 造形条件リスト情報
  const [materialTypeList, setMaterialTypeList] = React.useState<MyIF.materialType[]>([]);
  // ビューワーのところにあるメモ
  const [viewerMemo, setViewerMemo] = React.useState<string>('');
  // 計算が必要か制御フラグ
  // True:計算必要, False:計算必要なし
  const [calcFlg, setCalcFlg] = React.useState<boolean>(false);
  // Gavadonフック
  const gavadonRef = React.useRef<gavadonFuncHandle>(null!);
  // 型番詳細画面に遷移
  const navigateModelNumberReEdit = (modelNumber: string, labelName: string, isReEdit: boolean) => {
    navigate('/ModelNumberEdit', {
      state: {
        referrer: location.pathname,
        modelNumber: modelNumber,
        labelName: labelName,
        isReEdit: isReEdit,
      },
    });
  };

  /**
   * 型番仕様詳細取得API
   */
  React.useEffect(() => {
    (async () => {
      setProgressShow(true);

      //  造形条件取得API
      const materialResult = await (
        await APIController.build({
          setAlertShow: setAlertShow,
          navigate: navigate,
        })
      ).GetModelNumberMaterialList();
      if (materialResult !== null) {
        setMaterialTypeList(materialResult);
      }

      // 詳細取得API
      const result = await (
        await APIController.build({
          setAlertShow: setAlertShow,
          setAlertMessage: setMessageList,
          navigate: navigate,
        })
      ).GetModelNumberDetail(modelNumber);
      if (result !== null) {
        setLabelName(result.labelName);
        setThreeDFileName(result.threeDFileName);
        setSelectModelCondition(result.modelConditon);
        setSelectOptionInfo(result.optionInfo);
        await GetModelNumberOption(result.modelConditon.modelingMaterialId, result.optionInfo);
        setPriceInfo(result.priceInfo);
        setViewerMemo(result.viewerMemo);

        // 計算結果取得API
        if (result.modelViewInfo.id !== '') {
          const modelResult = await (
            await APIController.build({
              setAlertShow: setAlertShow,
              navigate: navigate,
            })
          ).GetModelingResult(result.modelViewInfo.id);
          if (modelResult !== null) {
            setIsModelingJudge(modelResult.isJudge);
            setModelingResultList(modelResult.modelingResult);
            setTrialCalcInfo(modelResult.trialCalcInfo);
            setModelViewInfo({
              ...result.modelViewInfo,
              supportMpacKey: modelResult.supportModel.key,
              supportBlob: modelResult.supportModel.blob,
            });
            setCalcFlg(false);
          }
        }
      }
      setProgressShow(false);
    })();
  }, []);

  // ベースプレートサイズ取得
  function GetBasePlateSize(): { x: number; y: number } | undefined {
    let size: { x: number; y: number } | undefined = undefined;
    if (selectModelCondition.printerValue !== '') {
      materialTypeList.forEach((e1) => {
        if (e1.materialValue === selectModelCondition.materialValue) {
          e1.methodList.forEach((e2) => {
            if (e2.methodValue === selectModelCondition.methodValue) {
              e2.printerList.forEach((e3) => {
                if (e3.name === selectModelCondition.printerValue) {
                  size = {
                    x: e3.maximumBuildSizeWidth,
                    y: e3.maximumBuildSizeDepth,
                  };
                }
              });
            }
          });
        }
      });
    }
    return size;
  }

  async function GetModelNumberOption(
    modelingMaterialId: number | undefined,
    inSelectOption = selectOptionInfo
  ) {
    setProgressShow(true);
    try {
      let tmpOptionList: MyIF.optionItem[] = [];
      // 材料を選択していない場合は呼び出ししない
      if (modelingMaterialId) {
        // API呼び出し
        const result = await (
          await APIController.build({
            setAlertShow: setAlertShow,
            navigate: navigate,
          })
        ).GetModelNumberOption(modelingMaterialId);
        if (result !== null) {
          tmpOptionList = result;
        }
      }

      // 現在選択中の情報とマージ
      // 基本ありえないが選択中の情報と齟齬があった場合は、
      // ここで取得したオプション情報をベースにする。
      // （選択してあったものが、ない場合はデフォルトに戻す）
      const newOptionSelect: MyIF.optionSelect[] = [];

      // 順番ずれると面倒なので同期ループ
      for (let ii = 0; ii < tmpOptionList.length; ii++) {
        const option: MyIF.optionItem = tmpOptionList[ii];
        let tmpSelect: MyIF.optionSelect = {
          labelName: option.labelName,
          exp: option.exp,
          items: option.items,
          maxNum: option.maxNum,
          perType: option.perType,
          select: [],
        };

        // 選択情報をメンテナンス
        for (let jj = 0; jj < inSelectOption.optionSelectList.length; jj++) {
          const optionSelect: MyIF.optionSelect = inSelectOption.optionSelectList[jj];
          if (option.labelName === optionSelect.labelName) {
            // 現在選択中のものが、リストにあるかチェック
            // 順番ずれると面倒なので同期ループ
            let chkFlg = false;
            for (let kk = 0; kk < optionSelect.select.length; kk++) {
              for (let ll = 0; ll < option.items.length; ll++) {
                if (optionSelect.select[kk].value === option.items[ll].value) {
                  // 一致しているメニューがあれば、そのまま追加
                  tmpSelect.select.push({
                    value: optionSelect.select[kk].value,
                    num: option.maxNum
                      ? optionSelect.select[kk].num === ''
                        ? '1'
                        : optionSelect.select[kk].num
                      : '',
                    calcFlg: optionSelect.select[kk].calcFlg,
                    index: uuid(),
                  });
                  chkFlg = true;
                  break;
                }
              }
            }
            // 選択していた項目がなくなっている場合
            if (chkFlg === false) {
              tmpSelect.select.push({
                value: option.maxNum ? '' : option.items[0].value,
                num: option.maxNum ? '1' : '',
                index: uuid(),
              });
            }
            break;
          }
        }

        if (tmpSelect.select.length === 0) {
          tmpSelect.select.push({
            value: option.maxNum ? '' : option.items[0].value,
            num: option.maxNum ? '1' : '',
            index: uuid(),
          });
        }

        newOptionSelect.push(tmpSelect);
      }
      setSelectOptionInfo({ ...inSelectOption, optionSelectList: newOptionSelect });
      console.log(newOptionSelect);
    } catch (err) {
      console.error(err);
    }
    setProgressShow(false);
  }

  return (
    <div>
      <FlowArea active={1}></FlowArea>
      <div className='mainContentsBlock'>
        <div className='modelNoSpecEditBlock'>
          <div className='modelNoSpecEditFormBlock'>
            <h1 className='pageTitle'>単価試算の仕様（参照）</h1>
            {/* 名称 */}
            <NameArea
              readOnly={true}
              modelNumber={modelNumber}
              labelName={labelName}
              setLabelName={setLabelName}
            ></NameArea>

            {/* 3Dデータ */}
            <ThreeDModelInputArea
              readOnly={true}
              fileName={threeDFileName}
              setFileName={setThreeDFileName}
              modelViewInfo={modelViewInfo}
              setModelViewInfo={setModelViewInfo}
            ></ThreeDModelInputArea>

            {/* 造形条件 */}
            <ModelConditionsArea
              readOnly={true}
              materialTypeList={materialTypeList}
              selectModelCondition={selectModelCondition}
              setSelectModelCondition={setSelectModelCondition}
              threeDFileName={threeDFileName}
              calcFlg={calcFlg}
            ></ModelConditionsArea>

            {calcFlg === false && (
              <div>
                {/* 造形可否判定結果 */}
                <ModelingResultArea
                  isModelingJudge={isModelingJudge}
                  modelingResultList={modelingResultList}
                ></ModelingResultArea>
                {/* 試算基本情報 */}
                <TrialCalcInfoArea trialCalcInfo={trialCalcInfo}></TrialCalcInfoArea>
                {/* オプション設定 */}
                <OptionArea
                  readOnly={true}
                  selectModelCondition={selectModelCondition}
                  selectOptionInfo={selectOptionInfo}
                  setSelectOptionInfo={setSelectOptionInfo}
                ></OptionArea>
                {/* 単価 */}
                <PriceAreaRef
                  setIsModalOpen={setIsModalMateHikakuOpen}
                  isModelingJudge={isModelingJudge}
                  priceInfo={priceInfo}
                ></PriceAreaRef>
              </div>
            )}
          </div>

          {/* 3Dビューワー */}
          <ViewerArea
            readOnly={true}
            viewerMemo={viewerMemo}
            setViewerMemo={setViewerMemo}
            selectModelCondition={selectModelCondition}
            threeDFileName={threeDFileName}
            modelViewInfo={modelViewInfo}
            gavadonRef={gavadonRef}
            GetBasePlateSize={GetBasePlateSize}
          ></ViewerArea>
        </div>
        <div className='fixedListControlBlock'>
          <div className='fixedListControlInner'>
            <button
              className='btnWhiteBaseGray'
              type='button'
              name='button'
              onClick={() => {
                navigate('/ModelNumberList');
              }}
              style={{ marginRight: '8px' }}
            >
              戻る
            </button>
            <button
              className='btnOrangeCommitWhite'
              type='button'
              name='button'
              onClick={() => {
                navigateModelNumberReEdit(modelNumber, labelName, true);
              }}
                >
              コピーして編集
            </button>
          </div>
          <FooterContent />
        </div>

        {/* 材料比較画面 */}
        <ModalMaterialHikaku
          show={isModalMateHikakuOpen}
          setIsModalOpen={setIsModalMateHikakuOpen}
          modelViewInfo={modelViewInfo}
          printerValue={selectModelCondition.printerValue}
        ></ModalMaterialHikaku>
      </div>
    </div>
  );
};

export default useModelNumberSpecRef;
