import * as React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { APIController } from '../../common/API';
import * as MyIF from '../../common/Interface';
import { useAlert } from '../../provider/alert/AlertProvider';
import { useProgress } from '../../provider/progress/ProgressProvider';
import '../../assets/css/designCommon.css';
import '../../assets/css/quotationRequest.css';
import '../../assets/common/normalize.css';
import '../../assets/common/reset.css';
import '../../assets/common/base2.css';
import FlowArea from '../flow/FlowArea';
import { viewSelectType } from '../../pages/modelNumberSpec/ModelNumberSpecEdit';
import { FileController } from '../../common/FileController';
import md5 from 'js-md5';
import FsLightbox from 'fslightbox-react';

interface Props {
  setViewSelect: React.Dispatch<React.SetStateAction<viewSelectType>>;
  deleteModelNumberList: MyIF.deleteModelNumber[];
}

const ModelReUpload: React.FC<Props> = React.memo(({ setViewSelect, deleteModelNumberList }) => {
  const { setAlertShow, setMessageList } = useAlert();
  const { setProgressShow } = useProgress();
  const navigate = useNavigate();
  const location = useLocation();

  const [checkCount, setCheckCount] = React.useState(0);

  /**
   * ハッシュ値取得
   * @param uploadFile
   * @returns
   */
  const getHash = async (uploadFile: File): Promise<string> => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(uploadFile);
    return new Promise((resolve) => {
      reader.onload = function () {
        const res = reader.result;
        if (res) {
          const hash = md5(res);
          resolve(hash);
        }
      };
    });
  };

  /**
   * Gzip圧縮
   * @param fileData
   * @param fileName
   * @returns
   */
  const getGzipFile = async (fileData: File, fileName: string) => {
    return await new FileController().GetCompressionGzip(fileData, fileName);
  };

  /**
   * アップロード
   * @param uploadFile
   * @param id
   * @returns
   */
  const fileUpload = async (uploadFile: File, id: string) => {
    setProgressShow(true);

    let rtnStatus = true;

    // 圧縮
    const gzip = await getGzipFile(
      uploadFile,
      uploadFile.name.split('.').pop()?.toLowerCase() === 'stl' ? 'model.stl' : 'model.step'
    );
    // ハッシュ値取得
    const hash = await getHash(gzip);

    // アップロード先のURLを取得
    const result = await (
      await APIController.build({
        setAlertShow: setAlertShow,
        navigate: navigate,
      })
    ).GetThreeDReUploadURL(uploadFile.name, id, hash, gzip.size);
    if (!result) {
      console.error('アップロードURL取得失敗');
      rtnStatus = false;
    } else {
      if (result.checkResult && result.checkResult.length > 0) {
        setMessageList(result.checkResult);
        setAlertShow(true);
        rtnStatus = false;
      } else {
        // ファイルアップロード
        const uploadURL = result.url;
        if (uploadURL) {
          const resultUpload = await new FileController().FileUploadURL(
            uploadURL,
            'application/gzip',
            gzip,
            'gzip'
          );
          if (!resultUpload) {
            console.error('ファイルアップロード失敗');
            rtnStatus = false;
          } else {
            // アップロード完了通知
            const result = await (
              await APIController.build({
                setAlertShow: setAlertShow,
                navigate: navigate,
              })
            ).PostThreeDDataUploadFinished(id);
            if (!result) {
              rtnStatus = false;
            }
          }
        } else {
          rtnStatus = false;
        }
      }
    }
    setProgressShow(false);
    return rtnStatus;
  };

  
  // 型番詳細画面に遷移
  const navigateModelNumberReEdit = (modelNumber: string, labelName: string, isReEdit: boolean) => {
    navigate('/ModelNumberEdit', {
      state: {
        referrer: location.pathname,
        modelNumber: modelNumber,
        labelName: labelName,
        isReEdit: isReEdit,
      },
    });
    if (typeof window !== 'undefined') {
      window.location.reload();
    }
  };

  const UploadModelNumberItems = (deleteModelNumber: MyIF.deleteModelNumber) => {
    const [fileName, setFileName] = React.useState('');
    const [disabled, setDisabled] = React.useState(false);
    const inputRef = React.useRef<HTMLInputElement>(null);
    const [isLightBoxOpen, setIsLightBoxOpen] = React.useState(false);

    const onFileInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files && event.target.files[0] !== undefined) {
        const result = await fileUpload(event.target.files[0], deleteModelNumber.threeDDataId);
        if (result) {
          setDisabled(result);
          setFileName(event.target.files[0].name);
          setCheckCount(checkCount + 1);
        }
      }
    };

    const uploadButtonClick = () => {
      if (inputRef.current) {
        inputRef.current.accept = '.stl,.stp,.step';
        inputRef.current.click();
      }
    };

    return (
      <div style={{ marginBottom: '20px' }}>
        <div className='uploadOneBlock'>
          <div className='ImageBlock'>
            <img
              src={deleteModelNumber.imageURL}
              alt=''
              onClick={() => setIsLightBoxOpen(!isLightBoxOpen)}
            ></img>
          </div>
          <div className='ContentsBlock'>
            <p>
              型番： {deleteModelNumber.modelNumber}（ファイル名：{deleteModelNumber.fileName}
              {deleteModelNumber.labelName === ''
                ? null
                : '  ラベル名：' + deleteModelNumber.labelName}
              ）
            </p>
            <div className='ButtonBlock'>
              {' '}
              {fileName !== '' && <p className='modelReuploadData'></p>}
              <button
                className='btnReUpload'
                type='button'
                name='button'
                onClick={uploadButtonClick}
                disabled={disabled}
                style={{ marginBottom: '5px' }}
              >
                モデルアップロード
              </button>
            </div>
            <input hidden ref={inputRef} type='file' onChange={onFileInputChange} />
          </div>
        </div>
        <FsLightbox
          toggler={isLightBoxOpen}
          type='image'
          sources={[deleteModelNumber.imageURL]}
        ></FsLightbox>
      </div>
    );
  };

  return (
    <div>
      <div className='mainContentsBlock' style={{ marginBottom: '20px' }}>
        <div className='pageTitleBlock'>
          <div className='pageTitleInner'>
            <div className='pageTitleWithBtn'>
              <h1 className='pageTitle'>再アップロードして下さい</h1>
            </div>
          </div>
        </div>

        <div className='pageTitleDetailText'>
          <p>
            3Dデータの保持期限を過ぎたためコピーできません。
            <br />
            再度アップロードをお願いします。
          </p>
        </div>

        <div className='modelReuploadArea'>
          {deleteModelNumberList.map((deleteModelNumber, index) => {
            return <div key={index}>{UploadModelNumberItems(deleteModelNumber)}</div>;
          })}
        </div>

        <div className='modelReuploadButtonArea'>
          <button
            className='btnBack'
            type='button'
            name='button'
            onClick={() => {
              navigate('/ModelNumberList');
            }}
          >
            戻る
          </button>
          <button
            className='btnOrangeCommit'
            type='button'
            name='button'
            // onClick={() => {
            //   setViewSelect('Main');
            // }}
            onClick={() => {
                navigateModelNumberReEdit(deleteModelNumberList?.[0]?.modelNumber, deleteModelNumberList?.[0]?.labelName, true);
              }}
            disabled={!(checkCount === deleteModelNumberList.length)}
          >
            コピーして編集
          </button>
        </div>
      </div>
    </div>
  );
});

export default ModelReUpload;
