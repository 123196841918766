import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { APIController } from '../../common/API';
import * as MyIF from '../../common/Interface';
import { useAlert } from '../../provider/alert/AlertProvider';
import FlowArea from '../../components/flow/FlowArea';
import NameArea from '../../components/modelNumberSpecDetails/NameArea';
import ThreeDModelInputArea from '../../components/modelNumberSpecDetails/ThreeDModelInputArea';
import ModelConditionsArea from '../../components/modelNumberSpecDetails/ModelingConditionsArea';
import ModelingResultArea from '../../components/modelNumberSpecDetails/ModelingResultArea';
import TrialCalcInfoArea from '../../components/modelNumberSpecDetails/TrialCalcInfoArea';
import OptionArea from '../../components/modelNumberSpecDetails/OptionArea';
import PriceArea from '../../components/modelNumberSpecDetails/PriceArea';
import ViewerArea from '../../components/modelNumberSpecDetails/ViewerArea';
import ModalMessage from '../../components/modelNumberSpecDetails/ModalMessage';
import ModalMaterialHikaku from '../../components/modelNumberSpecDetails/ModalMaterialHikaku';
import ModalModelingPostureEdit from '../../components/modelNumberSpecDetails/ModalModelingPostureEdit';
import CommonContact from '../../components/contactPerson/Contact';
import FooterContent from '../../components/footer/Footer';
import InformationMessage from '../../components/message/InformationMessage';
import { gavadonFuncHandle } from '../../components/viewer/Gavadon';
import { useProgress } from '../../provider/progress/ProgressProvider';
import ModalCalcCancel from '../../components/modelNumberSpecDetails/ModalCalcCancel';
import uuid from 'react-uuid';
import AlertFailCalcMessage from '../../components/message/AlertFailCalc';
import { calcBoxSize, degreeToRadian } from '../../common/CalcBoxSize';
import { CameraOutputDto, XyzDegDto } from '../../rest';
import CalcLoading from '../../components/modelNumberSpecDetails/CalcLoading';
import ModelReUpload from '../../components/modelNumberSpecDetails/ModelReUpload';

export type viewSelectType = 'Init' | 'Upload' | 'Main' | 'Edit' | 'Contract' | 'Complete';

const useModelNumberSpecEdit = () => {
  const { setAlertShow, setMessageList } = useAlert();
  const { setProgressShow } = useProgress();
  const location = useLocation();
  const navigate = useNavigate();
  
  const [viewSelect, setViewSelect] = React.useState<viewSelectType>('Init');
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [viewSelect]);
  // モデルが削除されている型番リスト
  const [deleteModelNumberList, setDeleteModelNumberList] = React.useState<
    MyIF.deleteModelNumber[]
  >([]);

  // 画面キャプチャ保存確認画面　制御用
  const [isModalMessageOpen, setIsModalMessageOpen] = React.useState(false);
  // 材料比較画面　制御用
  const [isModalMateHikakuOpen, setIsModalMateHikakuOpen] = React.useState(false);
  // 姿勢制御画面　制御用
  const [isModalModelingPosi, setIsModalModelingPosi] = React.useState(false);
  // 担当者連絡画面　制御用
  const [isContactPage, setIsContactPage] = React.useState(false);
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [isContactPage]);
  // 3D描画情報
  const [modelViewInfo, setModelViewInfo] = React.useState<MyIF.modelViewInfo>({
    id: '',
    modelMpacKey: '',
    bucketName: '',
    rotate: { x: 0, y: 0, z: 0 },
    image: '',
  });
  // 型番
  const [modelNumber, setModelNumber] = React.useState<string>(location.state.modelNumber);
  // ラベル名
  const [labelName, setLabelName] = React.useState<string>(location.state.labelName);
  // 3Dファイル名
  // （Fileオブジェクトから取得できるが、編集で開いたときはオブジェクトを取得しないため、ファイル名だけのフックを用意）
  const [threeDFileName, setThreeDFileName] = React.useState<string>();
  const [threeDFileSize, setThreeDFileSize] = React.useState<number>();
  // 造形条件
  const [selectModelCondition, setSelectModelCondition] =
    React.useState<MyIF.selectModelingConditionType>({
      modelingMaterialId: undefined,
      materialValue: '',
      methodValue: '',
      printerValue: '',
    });
  // 造形可否判定結果　1:OK 2:注記あり 3:NG
  const [isModelingJudge, setIsModelingJudge] = React.useState<number>(0);
  // 造形可否判定結果
  const [modelingResultList, setModelingResultList] = React.useState<MyIF.modelingResultType[]>();
  // 試算基本情報
  const [trialCalcInfo, setTrialCalcInfo] = React.useState<MyIF.trialCalcInfoType>();
  // オプション情報
  const [selectOptionInfo, setSelectOptionInfo] = React.useState<MyIF.optionInfo>({
    optionSelectList: [],
    // 指示書フラグ True:OFF　False:ON
    dirFlg: true,
    dirComment: '',
  });
  // 単価情報
  const [priceInfo, setPriceInfo] = React.useState<MyIF.priceInfo>({
    normal: {
      price: 123456789,
      date: '500',
    },
    express: {
      price: 123,
      date: '50',
    },
  });
  // 単価（10個プリント時）情報
  const [priceTenModelsInfo, setPriceTenModelsInfo] = React.useState<MyIF.priceTenModelsInfo>({
    normalPrice: 123456789,
    expressPrice: 123,
  });
  // 造形条件リスト情報
  const [materialTypeList, setMaterialTypeList] = React.useState<MyIF.materialType[]>([]);
  // ビューワーのところにあるメモ
  const [viewerMemo, setViewerMemo] = React.useState<string>('');
  // 計算が必要か制御フラグ
  // True:計算必要, False:計算必要なし
  const [calcFlg, setCalcFlg] = React.useState<boolean>(true);
  // 担当者連絡フラグ
  const [contactFlg, setContactFlg] = React.useState(false);
  // 初期モーダル制御
  const [isInitMessage, setIsInitMessage] = React.useState(true);
  // 10個計算失敗フラグ
  const [isFailCalc, setIsFailCalc] = React.useState(false);
  const handleInitClose = () => {
    setIsInitMessage(false);
  };
  // Gavadonフック
  const gavadonRef = React.useRef<gavadonFuncHandle>({} as gavadonFuncHandle);
  // 計算中モーダル制御
  const [isCalcCancel, setIsCalcCancel] = React.useState(false);
  const calcCancelRef = React.useRef(isCalcCancel);
  calcCancelRef.current = isCalcCancel;
  // 試算処理時間
  const [calcPredictTime, setCalcPredictTime] = React.useState<{
    h: number;
    m: number;
    s: number;
  }>();
  // モデルサイズ造形オーバー注意文言
  const [modelSizeOverMessage, setModelSizeOverMessage] = React.useState<string | undefined>(
    undefined,
  );
  const [modelSizeOverImg, setModelSizeOverImg] = React.useState<string | undefined>(undefined);
  // プリンタ情報
  const [printerInfo, setPrinterInfo] = React.useState<MyIF.threeDPrinter | undefined>(undefined);

  const [checkList, setCheckList] = React.useState<XyzDegDto[]>([]);
  const [cameraList, setCameraList] = React.useState<CameraOutputDto[]>([]);
  const [calcLoading, setCalcLoading] = React.useState(false);
  const refreshCalcRef = React.useRef(false);
  const calcRotateRef = React.useRef(false);

  // 担当者連絡フラグをメンテナンス
  React.useEffect(() => {
    let tmpFlg = false;
    if (isModelingJudge === 3) {
      tmpFlg = true;
    }
    setContactFlg(tmpFlg);
  }, [isModelingJudge]);

  const sleepTimeoutID = React.useRef<NodeJS.Timeout>();
  const sleepTimeout = async () => {
    return new Promise((resolve) => {
      sleepTimeoutID.current = setTimeout(resolve, 5000);
    });
  };

  /**
   * 造形可否判定結果取得
   */
  async function GetModelingResult(inModelViewInfo?: MyIF.modelViewInfo, isRecommendCalc: boolean = false) {
    let tmpInfo = inModelViewInfo ? inModelViewInfo : modelViewInfo;
    let status: boolean = true;

    let loopCount = 0;
    const timeOutCount = ((Number(process.env.REACT_APP_CALC_POLLING_TIME_OUT) || 40) * 60) / 5;
    console.log('timeOutCount', timeOutCount);

    // API呼び出し
    if (tmpInfo.id !== '') {
      while (true) {
        if (loopCount > timeOutCount) {
          setAlertShow(true);
          status = false;
          break;
        }

        // 5秒待機
        await sleepTimeout();

        const modelResult = isRecommendCalc ?
          await (
            await APIController.build({
              setAlertShow: setAlertShow,
              navigate: navigate,
            })
          ).GetRecommendModelingResult(tmpInfo.id) :
          await (
            await APIController.build({
              setAlertShow: setAlertShow,
              navigate: navigate,
            })
          ).GetModelingResult(tmpInfo.id);

        if (modelResult !== null) {
          if (modelResult.status === 0) {
            loopCount += 1;

            // キャンセル押されている場合は、終了
            if (calcCancelRef.current === false) {
              console.log('キャンセル押されたので終了');
              status = false;
              break;
            }
          } else if (modelResult.status === 1) {
            // TODO: サポート材最小探索計算かどうかで場合わけ
            if (isRecommendCalc) {
              tmpInfo.id = modelResult.threeDDataId ?? '';
              modelViewInfo.id = modelResult.threeDDataId ?? '';
              modelViewInfo.rotate.x = Number(modelResult.rotateInfo?.bank);
              modelViewInfo.rotate.y = Number(modelResult.rotateInfo?.attitude);
              modelViewInfo.rotate.z = Number(modelResult.rotateInfo?.heading);
              gavadonRef.current.setRotate(modelViewInfo.rotate);
            }
            setIsModelingJudge(modelResult.isJudge);
            setModelingResultList(modelResult.modelingResult);
            setTrialCalcInfo(modelResult.trialCalcInfo);
            tmpInfo.supportMpacKey = modelResult.supportModel.key;
            tmpInfo.supportBlob = modelResult.supportModel.blob;
            tmpInfo.onPartsSupportBlob = modelResult.onPartsSupportBlob;
            tmpInfo.crossSectionalNgBlob = modelResult.crossSectionalNgBlob;
            setCalcFlg(false);
            break;
          } else {
            status = false;
            break;
          }
        } else {
          status = false;
          break;
        }
      }
    }
    return { modelViewInfo: tmpInfo, status };
  }

  /**
   * 試算結果取得
   */
  async function GetCalcResult(
    id = modelViewInfo.id,
    selectOption = selectOptionInfo,
    selectCondition = selectModelCondition,
  ) {
    // API呼び出し
    if (
      selectOption.optionSelectList.length > 0 &&
      id !== '' &&
      selectCondition.modelingMaterialId
    ) {
      setProgressShow(true);
      const result = await (
        await APIController.build({
          setAlertShow: setAlertShow,
          navigate: navigate,
        })
      ).GetModelNumberTrialCalcResult(
        id,
        selectCondition.modelingMaterialId,
        selectCondition.methodValue,
        selectCondition.printerValue,
        selectOption.optionSelectList,
      );

      if (result !== null) {
        if (result.priceTenModelsInfo.normalPrice === null && result.priceTenModelsInfo.normalPrice === null) {
          console.log('10個計算失敗');
          setIsFailCalc(true);
        }
        setPriceInfo(result.priceInfo);
        setPriceTenModelsInfo(result.priceTenModelsInfo);
        setContactFlg(result.contactFlg);
      }
      setProgressShow(false);
    }
  }

  const closeFailCalcModal = () => {
    setIsFailCalc(false);
  };

  /**
   * 初期設定
   */
  React.useEffect(() => {
    (async () => {
      setProgressShow(true);
      //  造形条件取得API
      const result = await (
        await APIController.build({
          setAlertShow: setAlertShow,
          navigate: navigate,
        })
      ).GetModelNumberMaterialList();
      if (result !== null) {
        setMaterialTypeList(result);
      }

      // 型番が空白の場合は新規のため呼び出ししない
      if (modelNumber !== '') {
        // 削除の型番取得API
        const deleteModelNumber = await (
          await APIController.build({
            setAlertShow: setAlertShow,
            navigate: navigate,
          })
        ).GetModelNumberConfirmDeleted([modelNumber]);
        if (deleteModelNumber) {
          setDeleteModelNumberList(deleteModelNumber);
          if (deleteModelNumber.length > 0) {
            setViewSelect('Upload');
          } else {
            setViewSelect('Main');

            // 型番詳細取得API
            const result = await (
              await APIController.build({
                setAlertShow: setAlertShow,
                setAlertMessage: setMessageList,
                navigate: navigate,
              })
            ).GetModelNumberDetail(modelNumber, location.state.isReEdit);
            if (result !== null) {
              setLabelName(result.labelName);
              setThreeDFileName(result.threeDFileName);
              setSelectModelCondition(result.modelConditon);
              setSelectOptionInfo(result.optionInfo);
              await GetModelNumberOption(result.modelConditon.modelingMaterialId, result.optionInfo);
              setViewerMemo(result.viewerMemo);
              setThreeDFileSize(result.threeDFileSize);

              if (result.requireCalcFlg === false) {
                // 造形可否判定結果取得
                const resultViewInfo = await GetModelingResult(result.modelViewInfo);
                if (resultViewInfo.status) {
                  result.modelViewInfo = resultViewInfo.modelViewInfo;
                }

                // 試算結果取得
                await GetCalcResult(result.modelViewInfo.id, result.optionInfo, result.modelConditon);
              }


              refreshCalcRef.current = true;
              if (!!result.priceInfo?.normal?.price || location.state.modelNumber) {
                calcRotateRef.current = false;
              } else {
                calcRotateRef.current = true;
              }
              setModelViewInfo(result.modelViewInfo);
            }
          }
        }
      } else {
        setViewSelect('Main');
      }
      setProgressShow(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * サポート材最小計算実行
   */
  const RecommendCalcExecute = async () => {
    if (selectModelCondition.modelingMaterialId) {
      setIsCalcCancel(true);
      // カメラ情報
      // const camera = gavadonRef.current.getCamera();
      // 回転情報
      // const rotate = gavadonRef.current.getRotate();
      // 計算実行
      const result = await (
        await APIController.build({
          setAlertShow: setAlertShow,
          navigate: navigate,
        })
      ).PutThreeDModelRecommendCalc(
        modelViewInfo.id,
        selectModelCondition.modelingMaterialId,
        selectModelCondition.methodValue,
        selectModelCondition.printerValue,
        cameraList,
        checkList,
      );
      if (result) {
        let tmpViewInfo = Object.assign({}, modelViewInfo);
        if (result.groupID) {
          console.log('GroupID', result.groupID);
          tmpViewInfo.id = result.groupID;
        }

        // 造形可否判定結果取得
        const resultViewInfo = await GetModelingResult(tmpViewInfo, true);
        if (resultViewInfo.status) {
          setModelViewInfo(resultViewInfo.modelViewInfo);
          gavadonRef.current.reloadGround();

          // 試算結果取得
          await GetCalcResult(resultViewInfo.modelViewInfo.id);
        }
      }
      setIsCalcCancel(false);
    }
  };

  const initData = () => {
    setCalcFlg(true);
    setIsModelingJudge(0);
    setModelingResultList(undefined);
    setTrialCalcInfo(undefined);
    setPriceInfo({
      normal: {
        price: 0,
        date: '',
      },
      express: {
        price: 0,
        date: '',
      },
    });
    setPriceTenModelsInfo({
      normalPrice: 0,
      expressPrice: 0,
    });
    setModelViewInfo({
      id: '',
      supportMpacKey: '',
      supportBlob: undefined,
      modelMpacKey: '',
      modelBlob: undefined,
      bucketName: '',
      rotate: { x: 0, y: 0, z: 0 },
      image: '',
    });
    setCalcPredictTime(undefined);
    setModelSizeOverMessage(undefined);
    setCheckList([]);
    setCameraList([]);
  };

  // 3Dデータファイルを初期化されたときの動作
  React.useEffect(() => {
    if (threeDFileName === undefined) {
      initData();
    }
  }, [threeDFileName]);

  const onCalcBoxSize = React.useCallback((model?: MyIF.modelViewInfo, printer?: MyIF.threeDPrinter) => {
    refreshCalcRef.current = false;

    if (!model || !model.id || !printer) return;

    setCalcLoading(true);

    setTimeout(() => {
      const result = calcBoxSize(gavadonRef.current, printer!);

      if (result.checkedList.length > 0) {
        if (calcRotateRef.current) {
          const rotate = result.checkedList[0];

          const x = degreeToRadian(rotate.x!),
            y = degreeToRadian(rotate.y!),
            z = degreeToRadian(rotate.z!);

          gavadonRef.current.setRotate({ x, y, z });
          setModelViewInfo({ ...model!, rotate: { x, y, z } });
        }

        setModelSizeOverMessage('');
        setModelSizeOverImg(undefined);
      } else {
        const imagePath =
          process.env.PUBLIC_URL + '/RecommendImage/' + printer!.modelingAreaUrl;
        // プリロード
        const img = new Image();
        img.src = imagePath;

        setModelSizeOverMessage('モデルサイズが造形可能範囲を超えているために試算出来ません。造形姿勢を変更するか、より大型のプリンターを選択して下さい。');
        setModelSizeOverImg(imagePath);
      }

      setCalcLoading(false);
      calcRotateRef.current = false;
      setCheckList(result.checkedList);
      setCameraList(result.cameraList);
    }, 200);
  }, []);

  const onThreeDModelChange = (model: MyIF.modelViewInfo) => {
    refreshCalcRef.current = true;
    calcRotateRef.current = true;
    setModelViewInfo(model);
  };

  const saveClose = async (confirmFlg: boolean) => {
    setProgressShow(true);
    // キャプチャを取る
    let captureS: string | undefined;
    let captureL: string | undefined;

    if (modelViewInfo.modelBlob) {
      // カメラ位置を変更
      // await gavadonRef.current.setCamera({ x: 0, y: -(Math.PI * 0.25), z: Math.PI * 0.75 });
      captureS = await gavadonRef.current.getCapture();
      captureL = await gavadonRef.current.getCapture('VIEW');
    }

    // とりあえず保存
    const newNumber = await (
      await APIController.build({
        setAlertShow: setAlertShow,
        setAlertMessage: setMessageList,
        navigate: navigate,
      })
    ).PutModelNumberDetail(
      labelName,
      location.state.isReEdit ? '' : modelNumber,
      threeDFileName ? threeDFileName : '',
      selectModelCondition,
      selectOptionInfo,
      viewerMemo,
      calcFlg,
      modelViewInfo.id,
      captureS,
      captureL,
    );
    if (newNumber === null) {
      // エラーの場合は遷移させない
    } else {
      // 採番されていれば新しいのを使う
      const tmpNumber = newNumber ? newNumber : modelNumber;
      setModelNumber(tmpNumber);

      if (confirmFlg) {
        // 確定API呼び出し
        const result = await (
          await APIController.build({
            setAlertShow: setAlertShow,
            setAlertMessage: setMessageList,
            navigate: navigate,
          })
        ).PutModelNumberConfirm(tmpNumber);
        if (result) {
          console.log('確定API判定結果', result.confirmFlg);
          if (result.confirmFlg) {
            // 仕様確定
            navigate('/ModelNumberList');
          } else {
            // 担当者連絡
            setViewSelect('Contract');
            setIsContactPage(true);
          }
        }
      } else {
        // 保存
        navigate('/ModelNumberList');
      }
    }
    setProgressShow(false);
  };

  const saveAndMoveTrialCalc = async () => {
    setProgressShow(true);
    // キャプチャを取る
    let captureS: string | undefined;
    let captureL: string | undefined;

    if (modelViewInfo.modelBlob) {
      // カメラ位置を変更
      // await gavadonRef.current.setCamera({ x: 0, y: -(Math.PI * 0.25), z: Math.PI * 0.75 });
      captureS = await gavadonRef.current.getCapture();
      captureL = await gavadonRef.current.getCapture('VIEW');
    }

    // とりあえず保存
    const newNumber = await (
      await APIController.build({
        setAlertShow: setAlertShow,
        setAlertMessage: setMessageList,
        navigate: navigate,
      })
    ).PutModelNumberDetail(
      labelName,
      location.state.isReEdit ? '' : modelNumber,
      threeDFileName ? threeDFileName : '',
      selectModelCondition,
      selectOptionInfo,
      viewerMemo,
      calcFlg,
      modelViewInfo.id,
      captureS,
      captureL,
    );
    if (newNumber === null) {
      // エラーの場合は遷移させない
    } else {
      // 採番されていれば新しいのを使う
      const tmpNumber = newNumber ? newNumber : modelNumber;
      setModelNumber(tmpNumber);

      // 確定API呼び出し
      const result = await (
        await APIController.build({
          setAlertShow: setAlertShow,
          setAlertMessage: setMessageList,
          navigate: navigate,
        })
      ).PutModelNumberConfirm(tmpNumber);
      if (result) {
        console.log('確定API判定結果', result.confirmFlg);
        // 試算画面に遷移
        navigate('/TrialCalcEdit', {
          state: {
            referrer: location.pathname,
            trialNumber: '',
            labelName: '',
            modelNumberList: [newNumber],
          },
        });
      }
    }
    setProgressShow(false);
  };

  // 造形条件変わった場合は再計算
  React.useEffect(() => {
    setCalcFlg(true);

    let _printerInfo = undefined;
    if (selectModelCondition.printerValue !== '') {
      materialTypeList.forEach((e1) => {
        if (e1.materialValue === selectModelCondition.materialValue) {
          e1.methodList.forEach((e2) => {
            if (e2.methodValue === selectModelCondition.methodValue) {
              e2.printerList.forEach((e3) => {
                if (e3.name === selectModelCondition.printerValue) {
                  _printerInfo = e3;
                }
              });
            }
          });
        }
      });
    }
    setPrinterInfo(_printerInfo);

    // モデル以外を消す
    setModelViewInfo({
      ...modelViewInfo,
      supportMpacKey: undefined,
      supportBlob: undefined,
      onPartsSupportBlob: undefined,
      crossSectionalNgBlob: undefined,
    });

    // 計算結果も消す
    setIsModelingJudge(0);
    setModelingResultList(undefined);
    setTrialCalcInfo(undefined);
  }, [
    selectModelCondition.printerValue,
    selectModelCondition.materialValue,
    selectModelCondition.methodValue,
  ]);

  React.useEffect(() => {
    GetCalcTime();
    UpdateBasePlate();
    refreshCalcRef.current = true;
    calcRotateRef.current = true;
  }, [printerInfo]);

  React.useEffect(() => {
    if (refreshCalcRef.current) {
      onCalcBoxSize(modelViewInfo, printerInfo);
    }
  }, [modelViewInfo, onCalcBoxSize, printerInfo]);

  /**
   * オプションが変わったとき、試算する
   */
  const firstRef = React.useRef(true);
  React.useEffect(() => {
    (async () => {
      if (firstRef.current) {
        firstRef.current = false;
        return;
      }
      if (calcFlg === false) {
        // 試算結果取得
        await GetCalcResult();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectOptionInfo.optionSelectList]);

  /**
   * 試算キャンセル
   */
  const handleCalcCancel = () => {
    if (typeof sleepTimeoutID.current === 'number') {
      clearTimeout(sleepTimeoutID.current);
    }
    setIsCalcCancel(false);
  };

  // 試算処理時間算出
  function GetCalcTime(iPrinterInfo = printerInfo, iGavadonRef = gavadonRef, isRecommendCalc: boolean = true) {
    if (iPrinterInfo && threeDFileSize) {
      let limitDegree: number = iPrinterInfo.supportMaterialLimit;
      console.log('limitDegree', limitDegree);

      // Fr画像データ
      const frData = iGavadonRef.current.getFrImageData(limitDegree);
      console.log('FrImageData', frData);

      // バウンディングボックスサイズ
      const bbSize = iGavadonRef.current.getBoudingBoxSize();
      console.log('bbSize', bbSize);

      console.log('threeDFileSize', threeDFileSize);

      // 処理時間
      // (処理時間) = A * (画像サイズ) + B * (スライス枚数) + C * (ファイルサイズ[B]) + D * (外形X[mm]) + E * (外形Y[mm]) + F * (外形Z[mm]) + D
      const paramA = Number(process.env.REACT_APP_FR_PROCESS_TIME_PARAM_A || 0.021784500595643);
      const paramB = Number(process.env.REACT_APP_FR_PROCESS_TIME_PARAM_B || 1.12190315093326);
      const paramC = Number(process.env.REACT_APP_FR_PROCESS_TIME_PARAM_C || 0.00109579636742745);
      const paramD = Number(process.env.REACT_APP_FR_PROCESS_TIME_PARAM_D || 0.00466479296565116);
      const paramE = Number(process.env.REACT_APP_FR_PROCESS_TIME_PARAM_E || -0.00266638869526256);
      const paramF = Number(process.env.REACT_APP_FR_PROCESS_TIME_PARAM_F || -2.1087526360302);
      const paramG = Number(process.env.REACT_APP_FR_PROCESS_TIME_PARAM_G || -12.4289311826222);
      console.log('param', { paramA, paramB, paramC, paramD, paramE, paramF, paramG });

      const calcTimeSec =
        paramA * (frData.imageSize < 400 ? 400 : frData.imageSize) +
        paramB * frData.sliceNum +
        paramC * (threeDFileSize / 1000) +
        paramD * bbSize.x +
        paramE * bbSize.y +
        paramF * bbSize.z +
        paramG;

      console.log('Math.ceil(calcTimeSec)', Math.ceil(calcTimeSec));
      // 5の倍数にするための補正値
      const hosei = 5 - (Math.ceil(calcTimeSec) % 5);
      // 最低5秒にしたあと、回線速度を加味して無条件に＋5秒
      let calcTimeSecCeil = Math.max(5, Math.ceil(calcTimeSec) + hosei) + 5;
      calcTimeSecCeil = isRecommendCalc ? 2 * calcTimeSecCeil : calcTimeSecCeil;
      console.log('calcTimeSecCeil', calcTimeSecCeil);

      if (!Number.isFinite(calcTimeSecCeil) || Number.isNaN(calcTimeSecCeil)) {
        setCalcPredictTime(undefined);
      } else {
        const calcTime = {
          h: Math.floor(calcTimeSecCeil / 3600),
          m: Math.floor(calcTimeSecCeil / 60) % 60,
          s: calcTimeSecCeil % 60,
        };
        console.log('calcTime', calcTime);

        setCalcPredictTime(calcTime);
      }
    } else {
      setCalcPredictTime(undefined);
    }
  }

  // ベースプレートサイズ取得
  function GetBasePlateSize(iPrinterInfo = printerInfo): { x: number; y: number } | undefined {
    let size: { x: number; y: number } | undefined = undefined;
    if (iPrinterInfo) {
      size = {
        x: iPrinterInfo.maximumBuildSizeWidth,
        y: iPrinterInfo.maximumBuildSizeDepth,
      };
    }
    return size;
  }

  // ベースプレート更新
  function UpdateBasePlate(iPrinterInfo = printerInfo) {
    const size = GetBasePlateSize(iPrinterInfo);
    if (size) {
      gavadonRef.current?.showBasePlate?.(size.x, size.y);
    } else {
      gavadonRef.current?.showBasePlateInit?.();
    }
  }

  // モデルサイズチェック
  function CheckBoudingBoxSize(
    iPrinterInfo = printerInfo,
    iGavadonRef = gavadonRef,
  ): { message: string; image: string } | undefined {
    if (iGavadonRef.current) {
      const bbSize = iGavadonRef.current.getBoudingBoxSize();
      if (bbSize.x === Infinity) {
        return undefined;
      }
      if (iPrinterInfo) {
        if (
          bbSize.x > iPrinterInfo.maximumBuildSizeWidth ||
          bbSize.y > iPrinterInfo.maximumBuildSizeDepth ||
          bbSize.z > iPrinterInfo.maximumBuildSizeHeight
        ) {
          const imagePath =
            process.env.PUBLIC_URL + '/RecommendImage/' + iPrinterInfo.modelingAreaUrl;
          // プリロード
          const img = new Image();
          img.src = imagePath;

          return {
            message:
              'モデルサイズが造形可能範囲を超えているために試算出来ません。造形姿勢を変更するか、より大型のプリンターを選択して下さい。',
            image: imagePath,
          };
        } else {
          return { message: '', image: '' };
        }
      }
    }
    return { message: '', image: '' };
  }

  async function GetModelNumberOption(
    modelingMaterialId: number | undefined,
    inSelectOption = selectOptionInfo,
  ) {
    setProgressShow(true);
    try {
      let tmpOptionList: MyIF.optionItem[] = [];
      // 材料を選択していない場合は呼び出ししない
      if (modelingMaterialId) {
        // API呼び出し
        const result = await (
          await APIController.build({
            setAlertShow: setAlertShow,
            navigate: navigate,
          })
        ).GetModelNumberOption(modelingMaterialId);
        if (result !== null) {
          tmpOptionList = result;
        }
      }

      // 現在選択中の情報とマージ
      // 基本ありえないが選択中の情報と齟齬があった場合は、
      // ここで取得したオプション情報をベースにする。
      // （選択してあったものが、ない場合はデフォルトに戻す）
      const newOptionSelect: MyIF.optionSelect[] = [];

      // 順番ずれると面倒なので同期ループ
      for (let ii = 0; ii < tmpOptionList.length; ii++) {
        const option: MyIF.optionItem = tmpOptionList[ii];
        let tmpSelect: MyIF.optionSelect = {
          labelName: option.labelName,
          exp: option.exp,
          items: option.items,
          maxNum: option.maxNum,
          perType: option.perType,
          select: [],
        };

        // 選択情報をメンテナンス
        for (let jj = 0; jj < inSelectOption.optionSelectList.length; jj++) {
          const optionSelect: MyIF.optionSelect = inSelectOption.optionSelectList[jj];
          if (option.labelName === optionSelect.labelName) {
            // 現在選択中のものが、リストにあるかチェック
            // 順番ずれると面倒なので同期ループ
            let chkFlg = false;
            for (let kk = 0; kk < optionSelect.select.length; kk++) {
              for (let ll = 0; ll < option.items.length; ll++) {
                if (optionSelect.select[kk].value === option.items[ll].value) {
                  // 一致しているメニューがあれば、そのまま追加
                  tmpSelect.select.push({
                    value: optionSelect.select[kk].value,
                    num: option.maxNum
                      ? optionSelect.select[kk].num === ''
                        ? '1'
                        : optionSelect.select[kk].num
                      : '',
                    calcFlg: optionSelect.select[kk].calcFlg,
                    index: uuid(),
                  });
                  chkFlg = true;
                  break;
                }
              }
            }
            // 選択していた項目がなくなっている場合
            if (chkFlg === false) {
              tmpSelect.select.push({
                value: option.maxNum ? '' : option.items[0].value,
                num: option.maxNum ? '1' : '',
                index: uuid(),
              });
            }
            break;
          }
        }

        if (tmpSelect.select.length === 0) {
          tmpSelect.select.push({
            value: option.maxNum ? '' : option.items[0].value,
            num: option.maxNum ? '1' : '',
            index: uuid(),
          });
        }

        newOptionSelect.push(tmpSelect);
      }
      setSelectOptionInfo({ ...inSelectOption, optionSelectList: newOptionSelect });
      console.log(newOptionSelect);
    } catch (err) {
      console.error(err);
    }
    setProgressShow(false);
  }

  /**
   * 造形材料が変更されたときは、オプション項目も変わるため再取得
   */
  React.useEffect(() => {
    (async () => {
      if (selectModelCondition.modelingMaterialId) {
        await GetModelNumberOption(selectModelCondition.modelingMaterialId);
      }
    })();
  }, [selectModelCondition.modelingMaterialId]);

  return (
    <div style={{ display: 'flex', flexFlow: 'column', minHeight: '100vh' }}>
      <div style={{ flex: '1' }}>
        <FlowArea active={1}></FlowArea>
        {isContactPage ? (
          <CommonContact
            updateFlg={0}
            updateNumber={modelNumber}
            setIsContactPage={setIsContactPage}
          ></CommonContact>
        ) : viewSelect === 'Upload' && (
        <ModelReUpload
          setViewSelect={setViewSelect}
          deleteModelNumberList={deleteModelNumberList}
        ></ModelReUpload>
      )}{viewSelect === 'Main' && (
          <div className='mainContentsBlock'>
            <div className='modelNoSpecEditBlock'>
              <div className='modelNoSpecEditFormBlock'>
                <h1 className='pageTitle'>単価の試算（仕様の設定）</h1>
                {/* 名称 */}
                <NameArea
                  modelNumber={location.state.isReEdit ? '' : modelNumber}
                  labelName={labelName}
                  setLabelName={setLabelName}
                ></NameArea>

                {/* 3Dデータ */}
                <ThreeDModelInputArea
                  fileName={threeDFileName}
                  setFileName={setThreeDFileName}
                  modelViewInfo={modelViewInfo}
                  setModelViewInfo={onThreeDModelChange}
                  setThreeDFileSize={setThreeDFileSize}
                  initData={initData}
                ></ThreeDModelInputArea>

                {/* 造形条件 */}
                <ModelConditionsArea
                  materialTypeList={materialTypeList}
                  selectModelCondition={selectModelCondition}
                  setSelectModelCondition={setSelectModelCondition}
                  threeDFileName={threeDFileName}
                  CalcExecute={RecommendCalcExecute}
                  calcFlg={calcFlg}
                  modelViewInfo={modelViewInfo}
                  setIsModalModelingPosi={setIsModalModelingPosi}
                  modelSizeOverMessage={modelSizeOverMessage}
                  modelSizeOverImg={modelSizeOverImg}
                ></ModelConditionsArea>

                {calcFlg === false && (
                  <div>
                    {/* 造形可否判定結果 */}
                    <ModelingResultArea
                      isModelingJudge={isModelingJudge}
                      modelingResultList={modelingResultList}
                    ></ModelingResultArea>

                    {/* 試算基本情報 */}
                    <TrialCalcInfoArea trialCalcInfo={trialCalcInfo}></TrialCalcInfoArea>

                    {/* オプション設定 */}
                    <OptionArea
                      selectModelCondition={selectModelCondition}
                      selectOptionInfo={selectOptionInfo}
                      setSelectOptionInfo={setSelectOptionInfo}
                    ></OptionArea>

                    {/* 単価 */}
                    <PriceArea
                      setIsModalOpen={setIsModalMateHikakuOpen}
                      isModelingJudge={isModelingJudge}
                      priceInfo={priceInfo}
                      priceTenModelsInfo={priceTenModelsInfo}
                      saveAndMove={saveAndMoveTrialCalc}
                      contactFlg={contactFlg}
                      calcFlg={calcFlg}
                    ></PriceArea>
                  </div>
                )}
              </div>

              {/* 3Dビューワー */}
              <ViewerArea
                viewerMemo={viewerMemo}
                setViewerMemo={setViewerMemo}
                selectModelCondition={selectModelCondition}
                threeDFileName={threeDFileName}
                modelViewInfo={modelViewInfo}
                gavadonRef={gavadonRef}
                GetCalcTime={GetCalcTime}
                GetBasePlateSize={GetBasePlateSize}
              ></ViewerArea>
            </div>
            <div className='fixedListControlBlock'>
              <div className='fixedListControlInner'>
                <button
                  className='btnWhiteBaseGray'
                  type='button'
                  name='button'
                  onClick={() => {
                    navigate(-1);
                  }}
                  style={{ marginRight: '8px' }}
                >
                  戻る
                </button>
                <button
                  className='btnOrangeCommitWhite'
                  type='button'
                  name='button'
                  disabled={
                    threeDFileName ? (calcFlg ? true : isModelingJudge === 3 ? true : false) : true
                  }
                  onClick={() => {
                    saveClose(true);
                  }}
                >
                  {isModelingJudge === 3 ? '担当者連絡' : contactFlg ? '担当者連絡' : '仕様確定'}
                </button>
              </div>
              <FooterContent noUI={isContactPage} />
            </div>

            {/* 画面キャプチャ保存確認画面 */}
            <ModalMessage
              show={isModalMessageOpen}
              setIsModalOpen={setIsModalMessageOpen}
            ></ModalMessage>

            {/* 材料比較画面 */}
            <ModalMaterialHikaku
              show={isModalMateHikakuOpen}
              setIsModalOpen={setIsModalMateHikakuOpen}
              modelViewInfo={modelViewInfo}
              printerValue={selectModelCondition.printerValue}
            ></ModalMaterialHikaku>

            {/* 姿勢制御画面 */}
            <ModalModelingPostureEdit
              show={isModalModelingPosi}
              setIsModalOpen={setIsModalModelingPosi}
              isModelingJudge={isModelingJudge}
              setIsModelingJudge={setIsModelingJudge}
              modelingResultList={modelingResultList}
              setModelingResultList={setModelingResultList}
              trialCalcInfo={trialCalcInfo}
              setTrialCalcInfo={setTrialCalcInfo}
              modelViewInfo={modelViewInfo}
              setModelViewInfo={setModelViewInfo}
              setCalcFlg={setCalcFlg}
              gavadonRef={gavadonRef}
              selectModelCondition={selectModelCondition}
              printerInfo={printerInfo}
              GetBasePlateSize={GetBasePlateSize}
              CheckBoudingBoxSize={CheckBoudingBoxSize}
              calcPredictTime={calcPredictTime}
              GetCalcResult={GetCalcResult}
              GetCalcTime={GetCalcTime}
              cameraList={cameraList}
              checkList={checkList}
            ></ModalModelingPostureEdit>
          </div>
        )}
        <InformationMessage
          show={isInitMessage}
          title={'操作説明'}
          message={''}
          imagePath={'ModelNumberDetail.png'}
          handleClose={handleInitClose}
        ></InformationMessage>

        <ModalCalcCancel
          show={isCalcCancel}
          handleCancel={handleCalcCancel}
          calcPredictTime={calcPredictTime}
        ></ModalCalcCancel>

        <AlertFailCalcMessage
          show={isFailCalc}
          message={''}
          handleCancel={closeFailCalcModal}
        ></AlertFailCalcMessage>

        <CalcLoading show={calcLoading} />
      </div>
      <FooterContent noUI={isContactPage} />
    </div>
  );
};

export default useModelNumberSpecEdit;
