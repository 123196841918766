import * as React from 'react';
import { APIController } from '../../common/API';
import * as MyIF from '../../common/Interface';
import { useAlert } from '../../provider/alert/AlertProvider';
import { useNavigate, useLocation } from 'react-router-dom';
import '../../assets/common/normalize.css';
import '../../assets/common/reset.css';
import '../../assets/css/designCommon.css';
import '../../assets/css/modelNumberSpecList.css';
import SearchIcon from '../../assets//image/common/icon_search_keyword_blue.svg';
import FlowArea from '../flow/FlowArea';
import QEXP from '../../common/QuestionExp.json';
import QuestionPopper from '../popper/QuestionPopper';
import InformationMessage from '../message/InformationMessage';
import ConfirmMessage from '../message/ConfirmMessage';
import { useProgress } from '../../provider/progress/ProgressProvider';
import FooterContent from '../footer/Footer';
import { saveAs } from 'file-saver';
import { FilterItems, FILTER_ITEMS_STRINGS, GetPdfType } from '../../common/GetTrialPdf';

export default function TrialCalcSpecList() {
  const { setAlertShow, setMessageList } = useAlert();
  const { setProgressShow } = useProgress();
  const navigate = useNavigate();
  const location = useLocation();
  enum SortByItems {
    ASC = 'ASC',
    DESC = 'DESC',
  }
  enum SortItems {
    CHECK_BOX = 'checkBox',
    SPEC_NUMBER = 'specNumber',
    LABEL_NAME = 'labelName',
    PRICE = 'price',
    DATE = 'date',
    STATUS = 'status',
    UPDATE_DATE = 'updateDate',
  }
  const HEADER_ITEMS_STRINGS = {
    [SortItems.CHECK_BOX]: '',
    [SortItems.LABEL_NAME]: 'ラベル名',
    [SortItems.SPEC_NUMBER]: '試算番号',
    [SortItems.PRICE]: '価格',
    [SortItems.DATE]: '希望納期',
    [SortItems.STATUS]: 'ステータス',
    [SortItems.UPDATE_DATE]: '更新日時',
  };

  // メイン画面に表示する一覧
  const [trialNumberList, setTrialNumberList] = React.useState<MyIF.trialNumberList[]>([]);
  // どのステータスでフィルターをかけているか
  const [filterItem, setFilterItem] = React.useState<string>(FilterItems.ALL);
  // 各ステータス毎の一覧のHit数を格納
  const [tableFilter, setTableFilter] = React.useState<{ [name: string]: string }>({});
  // どのアイテムで降順、昇順をリクエストしたか記録しておく。
  const [sorting, setSorting] = React.useState<string>(SortItems.SPEC_NUMBER);
  // ソート順
  const [sortBy, setSortBy] = React.useState<string>('DESC');
  // 一覧の総ページ数
  const [totalPageNum, setTotalPageNum] = React.useState<number>(1);
  // 表示しているページ番号
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  // 検索しているキーワード
  const [searchKeyword, setSearchKeyword] = React.useState<string>();
  // 検索入力フォーム
  const searchKeywordInputRef = React.useRef<HTMLInputElement>(null!);
  // 1ページに表示するアイテムの数
  const perPage = 7;
  // チェックボックス用のState
  const [checkedItems, setCheckedItems] = React.useState<{ [modelNumber: string]: boolean }>({});
  const checkBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedItems({
      ...checkedItems,
      [event.target.id]: event.target.checked,
    });
  };
  // 初期モーダル制御
  const [isInitMessage, setIsInitMessage] = React.useState(true);
  const handleInitClose = () => {
    setIsInitMessage(false);
  };

  // 削除モーダル制御
  const [isDelMessage, setIsDelMessage] = React.useState(false);
  const handleDelCancel = () => {
    setIsDelMessage(false);
  };
  const handleDelOK = () => {
    deleteTrialNumber();
    setIsDelMessage(false);
  };

  /**
   * 試算仕様一覧取得API呼び出し
   */
  const GetTrialNumberList = React.useCallback(async () => {
    setTrialNumberList([]);

    // API呼び出し
    const result = await (
      await APIController.build({
        setAlertShow: setAlertShow,
        navigate: navigate,
      })
    ).GetTrialCalcList(
      sorting,
      sortBy,
      currentPage,
      filterItem,
      searchKeyword,
      Object.keys(checkedItems).filter((e) => checkedItems[e] === true)
    );
    if (result !== null) {
      setTrialNumberList(result.trialNumberList);

      let tmpFilter: {
        [name: string]: string;
      } = {
        [FilterItems.ALL]: String(result.filterCount.all ?? 0),
        [FilterItems.FINISH]: String(result.filterCount.finish ?? 0),
        [FilterItems.REQUEST]: String(result.filterCount.request ?? 0),
        [FilterItems.CONTACT]: String(result.filterCount.contract ?? 0),
        [FilterItems.WAIT]: String(result.filterCount.wait ?? 0),
        [FilterItems.TMPSAVE]: String(result.filterCount.tmpsave ?? 0),
        [FilterItems.CONTACT_WAIT_PRICE]: String(result.filterCount.contractWaitPrice ?? 0),
        [FilterItems.HUMAN_DEAL]: String(result.filterCount.humanDeal ?? 0),
      };
      setTableFilter(tmpFilter);

      // フィルターによって総件数が変わる
      let allCount = Number(tmpFilter[filterItem]);
      setTotalPageNum(Math.ceil(allCount / perPage) === 0 ? 1 : Math.ceil(allCount / perPage));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentPage,
    filterItem,
    searchKeyword,
    sortBy,
    sorting,
    FilterItems.ALL,
    FilterItems.FINISH,
    FilterItems.REQUEST,
    FilterItems.CONTACT,
    FilterItems.WAIT,
    FilterItems.TMPSAVE,
    FilterItems.HUMAN_DEAL,
  ]);

  /**
   * 初回呼び出し
   */
  React.useEffect(() => {
    GetTrialNumberList();
  }, [GetTrialNumberList]);

  /**
   * 試算削除API呼び出し
   */
  const deleteTrialNumber = async () => {
    setProgressShow(true);
    const result = await (
      await APIController.build({
        setAlertShow: setAlertShow,
        setAlertMessage: setMessageList,
        navigate: navigate,
      })
    ).DeleteTrialNumber(Object.keys(checkedItems).filter((e) => checkedItems[e] === true));
    if (result) {
      if (result.data.checkResult.length > 0) {
        setMessageList(result.data.checkResult);
        setAlertShow(true);
      } else {
        setCheckedItems({});
        await GetTrialNumberList();
      }
    }
    setProgressShow(false);
  };

  // ソート反転
  const sortItems = (sortItem: string) => {
    if (sortItem === sorting) {
      // 直前にソートした項目であれば逆転
      if (sortBy === SortByItems.DESC) {
        setSortBy(SortByItems.ASC);
      } else {
        setSortBy(SortByItems.DESC);
      }
    } else {
      // 初めてソートする項目であれば、降順にする
      setSortBy(SortByItems.DESC);
    }
    setSorting(sortItem);
    setCurrentPage(1);
  };

  // 試算詳細画面に遷移
  const navigateTrialCalcEdit = (
    trialNumber: string,
    labelName: string,
    readOnly: boolean,
    status: string
  ) => {
    if (readOnly) {
      navigate('/TrialCalcRef', {
        state: {
          referrer: location.pathname,
          trialNumber: trialNumber,
          labelName: labelName,
          pdfType: GetPdfType(status),
          flow4Flg:
            status === FILTER_ITEMS_STRINGS[FilterItems.FINISH] ||
            status === FILTER_ITEMS_STRINGS[FilterItems.REQUEST]
              ? true
              : false,
        },
      });
    } else {
      navigate('/TrialCalcEdit', {
        state: {
          referrer: location.pathname,
          trialNumber: trialNumber,
          labelName: labelName,
        },
      });
    }
  };

    // 複数個取りの試算コピーして編集画面に遷移
  const navigateTrialCalcReEdit = (trialNumber: string, labelName: string, isReEdit: boolean) => {
      navigate('/TrialCalcEdit', {
        state: {
          referrer: location.pathname,
          trialNumber: trialNumber,
          labelName: labelName,
          isReEdit: isReEdit,
        },
      });
  };

  const navigateRequestQuotation = async () => {
    // 変な試算仕様が選ばれていないかチェック
    const result = await (
      await APIController.build({
        setAlertShow: setAlertShow,
        navigate: navigate,
      })
    ).GetTrialNumberCheck(Object.keys(checkedItems).filter((e) => checkedItems[e] === true));
    if (result != null) {
      const checkedNumberList = Object.keys(checkedItems).filter((e) => checkedItems[e] === true);
      const trialItems = trialNumberList.filter(v => checkedNumberList.includes(v.specNumber));

      const checkResult: string[] = result.data.errorList;
      if (checkResult.length === 0) {
        navigate('/RequestQuotation', {
          state: {
            referrer: location.pathname,
            trialNumberList: checkedNumberList,
            trialItems: trialItems,
          },
        });
      } else {
        setMessageList(checkResult);
        setAlertShow(true);
      }
    }
  };

  async function GetQuotePdf(specNumber: string) {
    const result = await (
      await APIController.build({
        setAlertShow: setAlertShow,
        navigate: navigate,
      })
    ).GetQuotePdf(specNumber);
    if (result) {
      saveAs(result, '見積書-' + specNumber + '.pdf');
    }
  }

  React.useEffect(() => {
    // フィルターを変更したときは、選択しているものをリセット
    setCheckedItems({});
    setCurrentPage(1);
  }, [filterItem]);

  return (
    <div id='MainBody'>
      <FlowArea active={2}></FlowArea>
      <div className='mainContentsBlock'>
        <div className='pageTitleBlock'>
          <div className='pageTitleInner'>
            <div className='pageTitleWithBtn'>
              <h1 className='pageTitle'>複数個取りの試算一覧</h1>
            </div>
            <div className='searchInputBlock'>
              <span style={{ marginRight: '10px' }}>
                <QuestionPopper value={QEXP.TrialCalcList.SEARCH} />
              </span>

              <input
                ref={searchKeywordInputRef}
                type='search'
                className='searchfKeywordInput'
                role='search'
                placeholder='検索キーワードを入力'
                maxLength={50}
                name=''
                autoComplete='off'
                onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                    setSearchKeyword(event.currentTarget.value);
                    setCurrentPage(1);
                  }
                }}
              />
              <button
                type='button'
                name='button'
                className='searchfKeywordSubmit'
                onClick={() => {
                  setSearchKeyword(searchKeywordInputRef.current.value);
                  setCurrentPage(1);
                }}
              >
                <img src={SearchIcon} alt='' />
              </button>
            </div>
          </div>
        </div>
        <div className='trialCalcSpecListControlBlock'>
          {/* ステータスによってテーブルを絞り込む */}
          <div className='modelNumberListControlInner'>
            <ul className='modelNumberListNarrowingList'>
              {Object.entries(FILTER_ITEMS_STRINGS).map(([key, value]) => {
                return (
                  <li key={key} className='modelNumberListNarrowingListItem'>
                    <button
                      className='modelNumberListNarrowingListLink button-style-trans'
                      onClick={() => setFilterItem(key)}
                    >
                      <span
                        className={`${
                          key === filterItem ? 'modelNumberListNarrowingListItemActive' : null
                        } `}
                      >
                        {value}
                      </span>
                      <span className='modelNumberListNarrowingListItemCount'>
                        ({tableFilter[key]})
                      </span>
                    </button>
                  </li>
                );
              })}
            </ul>
          </div>

          {/* ページング */}
          <div className='modelNumberListPagingInner'>
            <div className='modelNumberListPagingBlock'>
              <p className='modelNumberListPagingPara'>全{tableFilter[filterItem]}件</p>
              <button
                className={
                  currentPage === 1
                    ? 'modelNumberListPagingLinkPrevDisabled button-style'
                    : 'modelNumberListPagingLinkPrevActive button-style'
                }
                onClick={() => {
                  if (currentPage > 1) {
                    setCurrentPage(currentPage - 1);
                  }
                }}
                disabled={currentPage === 1 ? true : false}
              ></button>
              <p className='modelNumberListPagingCount'>
                <span className='modelNumberListPagingCountDisplay'>{currentPage}</span> /{' '}
                {totalPageNum}ページ
              </p>
              <button
                className={
                  currentPage === totalPageNum
                    ? 'modelNumberListPagingLinkNextDisabled button-style'
                    : 'modelNumberListPagingLinkNextActive button-style'
                }
                onClick={() => {
                  if (totalPageNum > currentPage) {
                    setCurrentPage(currentPage + 1);
                  }
                }}
                disabled={currentPage === totalPageNum ? true : false}
              ></button>
            </div>
          </div>
        </div>

        {/* 一覧テーブル */}
        <div className='modelNumberListTableBlock'>
          <table className='modelNumberListTable'>
            <thead>
              <tr>
                {Object.entries(HEADER_ITEMS_STRINGS).map(([key, value]) => {
                  return (
                    <th key={key}>
                      {value}
                      <button
                        className={`${
                          sorting === key
                            ? sortBy === SortByItems.DESC
                              ? 'modelNumberListSortLink modelNumberListSortLinkDescending button-style'
                              : 'modelNumberListSortLink modelNumberListSortLinkAscending button-style'
                            : 'modelNumberListSortLink modelNumberListSortLinkDefalt button-style'
                        } `}
                        onClick={() => sortItems(key)}
                      ></button>
                    </th>
                  );
                })}
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {trialNumberList.map((trialNumber: MyIF.trialNumberList, i: number) => {
                return (
                  <tr key={i}>
                    <td>
                      <div className='modelNumberListTableItemThumb'>
                        <div className='customCheckBox'>
                          <input
                            id={trialNumber.specNumber}
                            type='checkbox'
                            name='checkbox'
                            value={trialNumber.specNumber}
                            className={`modelNumberListTableItemThumbCheckbox ${
                              trialNumber.status === FILTER_ITEMS_STRINGS[FilterItems.WAIT]
                                ? 'Orange'
                                : 'Blue'
                            }`}
                            checked={
                              checkedItems[trialNumber.specNumber]
                                ? checkedItems[trialNumber.specNumber]
                                : false
                            }
                            onChange={(event) => {
                              checkBoxChange(event);
                            }}
                          />
                        </div>
                        <img
                          src={trialNumber.image}
                          alt=''
                          className='modelNumberListTableItemThumbImage'
                        />
                      </div>
                    </td>
                    <td>
                      <div className='modelNumberListTableItemLabelname'>
                        {trialNumber.labelName}
                      </div>
                    </td>
                    <td>
                      <div
                        className='modelNumberListTableItemModelname'
                        style={{ wordBreak: 'normal', overflowWrap: 'normal' }}
                      >
                        {trialNumber.specNumber}
                      </div>
                    </td>
                    <td>
                      <div className='modelNumberListTableItemPrice'>
                        ￥{trialNumber.price ? trialNumber.price.toLocaleString() : '-'}
                      </div>
                    </td>
                    <td>
                      <div className='modelNumberListTableItemDelivery'>{trialNumber.date}</div>
                    </td>
                    <td>
                      <div className='modelNumberListTableItemStatus'>{trialNumber.status}</div>
                    </td>
                    <td>
                      <div className='modelNumberListTableItemDate'>{trialNumber.updateDate}</div>
                    </td>
                    <td className='btnQuoteDownloadTd'>
                      <div className='modelNumberListTableItemDownload'>
                        {GetPdfType(trialNumber.status) === 'Quote' ? (
                          <button
                            className='btnQuoteDownload'
                            type='button'
                            name='button'
                            onClick={() => {
                              GetQuotePdf(trialNumber.specNumber);
                            }}
                          >
                            正式見積ダウンロード
                          </button>
                        ) : GetPdfType(trialNumber.status) === 'disabled' ? (
                          <button className='btnQuoteDownload' type='button' name='button' disabled>
                            試算表ダウンロード
                          </button>
                        ) : (
                          <button
                            className='btnQuoteDownload'
                            type='button'
                            name='button'
                            onClick={() => {
                              navigate('/PDF', {
                                state: {
                                  referrer: location.pathname,
                                  trialNumber: trialNumber.specNumber,
                                },
                              });
                            }}
                          >
                            試算表ダウンロード
                          </button>
                        )}
                      </div>
                    </td>
                    <td>
                      <div className='modelNumberListTableItemToDetail'>
                        <button
                          className='modelNumberListTableItemCheckbtn'
                          onClick={() => {
                            const readOnly =
                              FILTER_ITEMS_STRINGS[FilterItems.TMPSAVE] === trialNumber.status
                                ? false
                                : true;
                            navigateTrialCalcEdit(
                              trialNumber.specNumber,
                              trialNumber.labelName,
                              readOnly,
                              trialNumber.status
                            );
                          }}
                        >
                          {FILTER_ITEMS_STRINGS[FilterItems.TMPSAVE] === trialNumber.status
                            ? '編集'
                            : '参照'}
                        </button>
                        <button
                          className='modelNumberListTableItemCheckbtn'
                          onClick={() => {
                            navigateTrialCalcReEdit(
                              trialNumber.specNumber,
                              trialNumber.labelName,
                              true,
                            );
                          }}
                        >
                          コピーして編集
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {trialNumberList?.length > 0 && (<button
            className={`btnOrangeCommit btnOrangeCommitBig btnOrangeCommitTable ${
              Object.values(checkedItems).filter((e) => e === true).length === 0 ? '' : 'btnOrangeCommitBigHoverEnable'
            }`}
            type='button'
            name='button'
            onClick={navigateRequestQuotation}
            disabled={
              Object.values(checkedItems).filter((e) => e === true).length === 0 ? true : false
            }
          >
            □をチェックして、<br />
            正式見積依頼へ進む
          </button>)}
        </div>
        <div className='fixedListControlBlock'>
          <div className='fixedListControlInner'>
            <p className='fixedListControlSelectCountPara'>
              選択中：<span>{Object.values(checkedItems).filter((e) => e === true).length}</span>件
            </p>
            <button
              className={`btnOrangeCommit btnOrangeCommitBig ${
                Object.values(checkedItems).filter((e) => e === true).length === 0 ? '' : 'btnOrangeCommitBigHoverEnable'
              }`}
              type='button'
              name='button'
              onClick={navigateRequestQuotation}
              disabled={
                Object.values(checkedItems).filter((e) => e === true).length === 0 ? true : false
              }
            >
              □をチェックして、<br />
              正式見積依頼へ進む
            </button>
            <button
              className='btnGrayDelete'
              type='button'
              name='button'
              onClick={() => {
                setIsDelMessage(true);
              }}
              disabled={
                Object.values(checkedItems).filter((e) => e === true).length === 0 ? true : false
              }
            >
              削除
            </button>
          </div>
          <FooterContent />
        </div>
      </div>

      <InformationMessage
        show={isInitMessage}
        title={'操作説明'}
        message={''}
        imagePath={'TrialCalcList.png'}
        handleClose={handleInitClose}
      ></InformationMessage>

      <ConfirmMessage
        show={isDelMessage}
        title={'注意'}
        message={'削除します。よろしいですか？'}
        handleOK={handleDelOK}
        handleCancel={handleDelCancel}
      ></ConfirmMessage>
    </div>
  );
}
