import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useAuth } from './provider/cognito/AuthContext';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import TopPage from './pages/TopPage';
import Login from './pages/users/Login';
import LogOut from './pages/users/LogOut';
import UserRegist from './pages/users/UserRegist';
import ModelNumberSpecList from './pages/modelNumberSpec/ModelNumberSpecList';
import ModelNumberSpecEdit from './pages/modelNumberSpec/ModelNumberSpecEdit';
import ModelNumberSpecRef from './pages/modelNumberSpec/ModelNumberSpecRef';
import TrialCalcSpecList from './pages/trialCalcSpec/TrialCalcSpecList';
import TrialCalcSpecEdit from './pages/trialCalcSpec/TrialCalcSpecEdit';
import TrialCalcSpecRef from './pages/trialCalcSpec/TrialCalcSpecRef';
import RequestQuotation from './pages/requestQuotation/RequestQuotation';
import PDF from './pages/pdf/PDF';
import AlertMessage from './components/message/Alert';
import HeaderContent from './components/header/Header';
import FooterContent from './components/footer/Footer';
import ProgressRing from './components/progress/Progress';
import UserRegistComplete from './pages/users/UserRegistComplete';
import UserRegistConfirm from './pages/users/UserRegistConfirm';

function App() {
  const { isAuthenticated, user, isLoading, userName } = useAuth();
  console.log('WEB_API_ENDPOINT', process.env.REACT_APP_WEB_API_ENDPOINT);
  console.log('isLoading', isLoading);
  console.log('isAuthenticated:', isAuthenticated);
  console.log('user:', user);

  type routeConfig = {
    [address: string]: JSX.Element;
  };

  // ログイン後のルーティングリスト
  const routing: routeConfig = {
    '/ModelNumberList': <ModelNumberSpecList />,
    '/ModelNumberEdit': <ModelNumberSpecEdit />,
    '/ModelNumberRef': <ModelNumberSpecRef />,
    '/TrialCalcList': <TrialCalcSpecList />,
    '/TrialCalcEdit': <TrialCalcSpecEdit />,
    '/TrialCalcRef': <TrialCalcSpecRef />,
    '/RequestQuotation': <RequestQuotation />,
    '/PDF': <PDF />,
  };

  if (isLoading) {
    return (
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
        <CircularProgress color='inherit' />
      </Backdrop>
    );
  }

  return (
    <Router>
      <Routes>
        {/* トップページ */}
        <Route
          path='/*'
          element={
            <div>
              <TopPage />
              <AlertMessage />
              <ProgressRing />
            </div>
          }
        />

        {/* 新規登録 */}
        <Route
          path='/UserRegister'
          element={
            <div>
              <UserRegist />
              <AlertMessage />
              <ProgressRing />
            </div>
          }
        />

        {/* 本登録完了 */}
        <Route
          path='/UserRegisterComplete'
          element={
            <div>
              <UserRegistComplete />
            </div>
          }
        />

        <Route
          path='/UserRegisterConfirm'
          element={
            <div>
              <UserRegistConfirm />
              <ProgressRing />
            </div>
          }
        />

        {/* ログイン */}
        <Route
          path='/Login'
          element={
            // isAuthenticated && user && user.challengeName !== 'NEW_PASSWORD_REQUIRED' ? (
            //   <Navigate to='/ModelNumberList' />
            // ) : (
            //   <div>
            //     <Login />
            //     <AlertMessage />
            //   </div>
            // )
            <div>
              <Login />
              <AlertMessage />
              <ProgressRing />
            </div>
          }
        />

        {/* ログアウト */}
        <Route
          path='/LogOut'
          element={
            <div>
              <LogOut />
              <AlertMessage />
              <ProgressRing />
            </div>
          }
        />

        {/* ログイン後 */}
        {Object.keys(routing).map((key: string) => (
          <Route
            key={key}
            path={key}
            element={
              // セッション情報があるかチェック
              isAuthenticated && user ? (
                <div style={{ display: 'flex', flexFlow: 'column', minHeight: '100vh' }}>
                  <div style={{ flex: '1' }}>
                    <HeaderContent userName={userName ? userName : ''} />
                    {routing[key]}
                  </div>
                  {key == '/RequestQuotation' && <FooterContent noUI={true} />}
                  <AlertMessage />
                  <ProgressRing />
                </div>
              ) : (
                // セッション切れの場合はTOPへリダイレクト
                <Navigate to='/' />
              )
            }
          />
        ))}
      </Routes>
    </Router>
  );
}

export default App;
