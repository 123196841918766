import React from 'react';
import * as API from '../rest/api';
import * as MyIF from './Interface';
import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { Configuration } from '../rest';
import { FileController } from './FileController';
import { Auth } from 'aws-amplify';
import { NavigateFunction } from 'react-router-dom';
import { CachedS3ContentProvider } from './CachedS3ContentProvider';
import { CameraOutputDto, XyzDegDto } from '../rest/api';

export interface apiControllerConfig {
  setAlertShow?: React.Dispatch<React.SetStateAction<boolean>>;
  setAlertMessage?: React.Dispatch<React.SetStateAction<string[]>>;
  idToken?: string;
  navigate?: NavigateFunction;
}

export class APIController {
  private axiosInstance: AxiosInstance;
  private config: Configuration;
  private setAlertShow?: React.Dispatch<React.SetStateAction<boolean>>;
  private setAlertMessage?: React.Dispatch<React.SetStateAction<string[]>>;
  private setAlertShowWarapper = (
    flg: boolean,
    { code, msg }: { code?: number; msg?: string } = {}
  ) => {
    if (this.setAlertShow) {
      this.setAlertShow(flg);
    }
    if (this.setAlertMessage && code === 400 && !!msg) {
      this.setAlertMessage([msg]);
    }
  };
  private navigate: NavigateFunction | undefined;

  constructor(config: apiControllerConfig) {
    this.navigate = config.navigate;
    this.setAlertShow = config.setAlertShow;
    this.setAlertMessage = config.setAlertMessage;
    this.axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_WEB_API_ENDPOINT,
      headers: {
        Authorization: config.idToken ? config.idToken : '',
      },
      timeout: 60000,
    });

    this.axiosInstance.interceptors.request.use((config) => {
      return config;
    });

    // レスポンス前に割り込む処理
    this.axiosInstance.interceptors.response.use(
      (config) => this.requestSuccess(config),
      (config) => this.requestFailure(config)
    );
    this.config = new Configuration({
      basePath: process.env.REACT_APP_WEB_API_ENDPOINT,
    });
  }

  /**
   * クラス生成用
   * @param config
   * @returns
   */
  public static async build(config: apiControllerConfig): Promise<APIController> {
    let token = '';
    try {
      token = (await Auth.currentSession()).getIdToken().getJwtToken();
    } catch (err) {
      token = '';
    }
    config.idToken = token;
    const controller = new APIController(config);
    return controller;
  }

  /**
   * request失敗した時の処理
   * @param config
   * @returns
   */
  requestFailure = (config: any) => {
    console.error('RESPONSE FAILURE', config);
    if (config.response && config.response.status === 401) {
      if (this.navigate) {
        this.navigate('/');
        return Promise.resolve(config);
      }
    } else {
      this.setAlertShowWarapper(true, {
        code: config.response?.status,
        msg: config.response?.data?.message,
      });
    }
    return Promise.reject(config);
  };

  /**
   * request成功した時の処理
   * @param config
   * @returns
   */
  requestSuccess = (config: AxiosResponse<any, any>) => {
    return config;
  };

  /**
   * インフォメーション情報取得
   * @returns
   */
  GetReleaseInfo = async () => {
    try {
      const result = await new API.GetInformationControllerApi(
        this.config,
        '',
        this.axiosInstance
      ).getInformation();

      if (result.status !== 200) {
        return null;
      }

      const resultList: MyIF.releaseInfo[] = [];
      result.data.informationList.forEach((element) => {
        resultList.push({
          date: element.date,
          type: element.type === 'INFO' ? 'インフォメーション' : 'プレスリリース',
          title: element.title,
          content: element.content,
          link: element.link,
        });
      });
      return resultList;
    } catch (err) {
      this.setAlertShowWarapper(true);
      console.error(err);
      return null;
    }
  };

  /**
   * 試算チェックAPI
   * @param trialNumber
   * @returns
   */
  GetTrialNumberCheck = async (trialNumber: string[]) => {
    try {
      const result = await new API.GetCanCreateQuotationControllerApi(
        this.config,
        '',
        this.axiosInstance
      ).getCanCreateQuotation({
        trialCalcList: trialNumber,
      });

      if (result.status !== 200) {
        return null;
      }

      return result;
    } catch (err) {
      this.setAlertShowWarapper(true);
      console.error(err);
      return null;
    }
  };

  /**
   * 型番仕様確定
   * @param modelNumber
   * @returns
   */
  PutModelNumberConfirm = async (modelNumber: string) => {
    try {
      const result = await new API.PutModelNumberConfirmControllerApi(
        this.config,
        '',
        this.axiosInstance
      ).putModelNumberConfirm({
        modelNumber: modelNumber,
      });

      if (result.status !== 200) {
        return null;
      }

      return { confirmFlg: result.data.confirmFlg };
    } catch (err) {
      this.setAlertShowWarapper(true);
      console.error(err);
      return null;
    }
  };

  /**
   * 型番仕様保存
   * @param labelName
   * @param modelNumber
   * @param fileName
   * @param selectModelCondition
   * @param selectOptionInfo
   * @param priceInfo
   * @param viewerMemo
   * @param calcFlg
   * @param threeDDataID
   * @param captureS
   * @param captureL
   * @returns
   */
  PutModelNumberDetail = async (
    labelName: string,
    modelNumber: string,
    fileName: string,
    selectModelCondition: MyIF.selectModelingConditionType,
    selectOptionInfo: MyIF.optionInfo,
    viewerMemo: string,
    calcFlg: boolean,
    threeDDataID: string,
    captureS: string | undefined,
    captureL: string | undefined
  ) => {
    try {
      let optionList: {
        labelName: string;
        value: string;
        num?: string;
      }[] = [];

      selectOptionInfo.optionSelectList.forEach((value) => {
        value.select.forEach((select) => {
          optionList.push({
            labelName: value.labelName,
            value: select.value,
            num: select.num,
          });
        });
      });

      const result = await new API.PutModelNumberDetailControllerApi(
        this.config,
        '',
        this.axiosInstance
      ).putModelNumberDetail({
        labelName: labelName,
        modelNumber: modelNumber,
        fileName: fileName,
        modelingMaterialId: selectModelCondition.modelingMaterialId,
        methodValue: selectModelCondition.methodValue,
        printerValue: selectModelCondition.printerValue,
        optionList: optionList,
        // 指示書フラグ True:OFF　False:ON
        directions: !selectOptionInfo.dirFlg,
        directionsFileName: selectOptionInfo.dirFileName ? selectOptionInfo.dirFileName : '',
        directionsComment: selectOptionInfo.dirComment,
        directionsFileSize: selectOptionInfo.dirFile?.size,
        memo: viewerMemo,
        requireCalcFlg: calcFlg,
        threeDDataId: threeDDataID,
        captureSmall: captureS,
        captureLarge: captureL,
      });

      if (result.status !== 200) {
        return null;
      }

      if (result.data.directionsFileUploadUrl && selectOptionInfo.dirFile) {
        const resultUpload = await new FileController().FileUploadURL(
          result.data.directionsFileUploadUrl,
          'application/octet-stream',
          selectOptionInfo.dirFile
        );
        if (!resultUpload) {
          throw '[Error] ファイルアップロード失敗';
        }
      }

      return result.data.modelNumber;
    } catch (err) {
      this.setAlertShowWarapper(true);
      console.error(err);
      return null;
    }
  };

  /**
   * 試算仕様確定
   * @param trialNumber
   * @returns
   */
  PutTrialCalcConfirm = async (trialNumber: string) => {
    try {
      const result = await new API.PutTrialCalcConfirmControllerApi(
        this.config,
        '',
        this.axiosInstance
      ).putTrialCalcConfirm({
        specNumber: trialNumber,
      });

      if (result.status !== 200) {
        return null;
      }

      return { confirmFlg: result.data.confirmFlg };
    } catch (err) {
      this.setAlertShowWarapper(true);
      console.error(err);
      return null;
    }
  };

  /**
   * 試算仕様保存
   * @param specNumber
   * @param labelName
   * @param modelNumberList
   * @param date
   * @param captureSmall
   * @param captureLargeList
   * @returns
   */
  PutTrialCalc = async (
    specNumber: string,
    labelName: string,
    modelNumberList: MyIF.addModelNumber[],
    date: string,
    captureSmall?: string,
    captureLargeList?: string[]
  ) => {
    try {
      const result = await new API.PutTrialCalcControllerApi(
        this.config,
        '',
        this.axiosInstance
      ).putTrialCalc({
        specNumber,
        labelName,
        modelNumberList,
        // prettier-ignore
        // eslint-disable-next-line
        date: date.replaceAll('\/', '-'),
        captureSmall,
        captureLargeList,
      });

      if (result.status !== 200) {
        return null;
      }

      return result.data.specNumber;
    } catch (err) {
      this.setAlertShowWarapper(true);
      console.error(err);
      return null;
    }
  };

  /**
   * 造形可否判定 計算実行
   * @param threeDDataID
   * @param modelingMaterialId
   * @param methodValue
   * @param printerValue
   * @param camera
   * @returns
   */
  PutThreeDModelCalc = async (
    threeDDataID: string,
    modelingMaterialId: number,
    methodValue: string,
    printerValue: string,
    camera: MyIF.camera,
    rotate: MyIF.rotate
  ) => {
    try {
      const result = await new API.PostThreeDModelCalcControllerApi(
        this.config,
        '',
        this.axiosInstance
      ).postThreeDModelCalc({
        threeDDataID: threeDDataID,
        modelingMaterialId: modelingMaterialId,
        methodValue: methodValue,
        printerValue: printerValue,
        camera: {
          up: camera.up,
          pos: camera.dir,
        },
        rotateInfo: {
          bank: rotate.x.toString(),
          attitude: rotate.y.toString(),
          heading: rotate.z.toString(),
        },
      });

      if (result.status !== 200) {
        return null;
      }

      return { newID: result.data.newThreeDDataID };
    } catch (err) {
      this.setAlertShowWarapper(true);
      console.error(err);
      return null;
    }
  };

  /**
   * 型番仕様_試算結果取得
   * @param threeDDataID
   * @param modelingMaterialId
   * @param methodValue
   * @param printerValue
   * @param optionSelectList
   * @returns
   */
  GetModelNumberTrialCalcResult = async (
    threeDDataID: string,
    modelingMaterialId: number,
    methodValue: string,
    printerValue: string,
    optionSelectList: MyIF.optionSelect[]
  ) => {
    try {
      let inOptionList: {
        labelName: string;
        value: string;
        num?: string;
      }[] = [];

      optionSelectList.forEach((optionSelect) => {
        optionSelect.select.forEach((select) => {
          inOptionList.push({
            labelName: optionSelect.labelName,
            value: select.value,
            num: select.num === '' ? undefined : select.num,
          });
        });
      });

      const result = await new API.GetModelNumberTrialCalcResultControllerApi(
        this.config,
        '',
        this.axiosInstance
      ).getModelNumberTrialCalcResult({
        threeDDataId: threeDDataID,
        modelingMaterialId: modelingMaterialId,
        methodValue: methodValue,
        printerValue: printerValue,
        optionList: inOptionList,
      });

      if (result.status !== 200) {
        return null;
      }

      const resultPrice: MyIF.priceInfo = result.data.calcResult
        ? result.data.calcResult
        : {
            normal: {
              price: undefined,
              date: undefined,
            },
            express: {
              price: undefined,
              date: undefined,
            },
          };
      const resultPriceTenModels: MyIF.priceTenModelsInfo = result.data.calcTenModelsResult
        ? result.data.calcTenModelsResult
        : {
            normalPrice: undefined,
            expressPrice: undefined,
          };
      return {
        priceInfo: resultPrice,
        priceTenModelsInfo: resultPriceTenModels,
        contactFlg: result.data.contactFlg,
      };
    } catch (err) {
      this.setAlertShowWarapper(true);
      console.error(err);
      return null;
    }
  };

  /**
   * 造形可否判定 サポート材最小探索計算実行
   * @param threeDDataID
   * @param modelingMaterialId
   * @param methodValue
   * @param printerValue
   * @param cameraOutputDtoList
   * @param xyzDegDtoList
   * @param cameraOutputDtoList
   * @param xyzDegDtoList
   * @returns
   */
  PutThreeDModelRecommendCalc = async (
    threeDDataID: string,
    modelingMaterialId: number,
    methodValue: string,
    printerValue: string,
    cameraOutputDtoList: CameraOutputDto[],
    xyzDegDtoList: XyzDegDto[]
  ) => {
    try {
      const result = await new API.PostThreeDModelRecommendCalcControllerApi(
        this.config,
        '',
        this.axiosInstance
      ).postThreeDModelRecommendCalc({
        threeDDataID: threeDDataID,
        modelingMaterialId: modelingMaterialId,
        methodValue: methodValue,
        printerValue: printerValue,
        cameraOutputDtoList,
        xyzDegDtoList,
      });

      if (result.status !== 200) {
        return null;
      }

      return { groupID: result.data.groupID };
    } catch (err) {
      this.setAlertShowWarapper(true);
      console.error(err);
      return null;
    }
  };

  /**
   * 3Dモデル_存在チェック
   * @param trialNumberList
   */
  GetDeletedModelNumbers = async (trialNumberList: string[]) => {
    let deleteModelNumberList: MyIF.deleteModelNumber[] = [];
    try {
      const result = await new API.GetDeletedModelNumbersControllerApi(
        this.config,
        '',
        this.axiosInstance
      ).getDeletedModelNumbers({
        specNumber: trialNumberList,
      });

      if (result.status !== 200) {
        return null;
      }

      for (let ii = 0; ii < result.data.deletedModelNumbers.length; ii++) {
        const getData = result.data.deletedModelNumbers[ii];
        const resultURL = await new FileController().GetPresignedURL(
          getData.captureBucketName,
          getData.captureLargeKey
        );
        const imageURL = resultURL ? resultURL : '';
        deleteModelNumberList.push({ ...getData, imageURL: imageURL });
      }
      return deleteModelNumberList;
    } catch (err) {
      this.setAlertShowWarapper(true);
      console.error(err);
      return null;
    }
  };

  /**
   * 3Dモデル_存在チェック
   * @param trialNumberList
   */
  GetModelNumberConfirmDeleted = async (trialNumberList: string[]) => {
    let deleteModelNumberList: MyIF.deleteModelNumber[] = [];
    try {
      const result = await new API.GetModelNumberConfirmDeletedControllerApi(
        this.config,
        '',
        this.axiosInstance
      ).getModelNumberConfirmDeleted({
        specNumber: trialNumberList,
      });

      if (result.status !== 200) {
        return null;
      }

      for (let ii = 0; ii < result.data.deletedModelNumbers.length; ii++) {
        const getData = result.data.deletedModelNumbers[ii];
        const resultURL = await new FileController().GetPresignedURL(
          getData.captureBucketName,
          getData.captureLargeKey
        );
        const imageURL = resultURL ? resultURL : '';
        deleteModelNumberList.push({ ...getData, imageURL: imageURL });
      }
      return deleteModelNumberList;
    } catch (err) {
      this.setAlertShowWarapper(true);
      console.error(err);
      return null;
    }
  };

  /**
   * ３DファイルアップロードURL取得API
   * @param fileName
   * @returns
   */
  GetThreeDUploadURL = async (fileName: string, fileSize: number) => {
    try {
      const result = await new API.GetThreeDUploadUrlControllerApi(
        this.config,
        '',
        this.axiosInstance
      ).getThreeDUploadURL({
        fileName,
        fileSize,
      });

      if (result.status !== 200) {
        return null;
      }

      return { id: result.data.threeDDataID, url: result.data.uploadURL };
    } catch (err) {
      this.setAlertShowWarapper(true);
      console.error(err);
      return null;
    }
  };

  /**
   * ３Dファイル再アップロードURL取得API
   * @param fileName
   * @param threeDDataID
   * @returns
   */
  GetThreeDReUploadURL = async (
    fileName: string,
    threeDDataID: string,
    fileHash: string,
    fileSize: number
  ) => {
    try {
      const result = await new API.GetThreeDReUploadUrlControllerApi(
        this.config,
        '',
        this.axiosInstance
      ).getThreeDReUploadURL({
        threeDDataID,
        fileName,
        fileHash,
        fileSize,
      });

      if (result.status !== 200) {
        return null;
      }

      return { checkResult: result.data.errorList, url: result.data.uploadURL };
    } catch (err) {
      this.setAlertShowWarapper(true);
      console.error(err);
      return null;
    }
  };

  /**
   * 連絡先情報チェックAPI
   * @param userInfo
   * @returns
   */
  GetContactAddressCheck = async (userInfo: MyIF.userInfo) => {
    try {
      const result = await new API.PutContactCheckControllerApi(
        this.config,
        '',
        this.axiosInstance
      ).putContactCheck({
        lastName: userInfo.lastName,
        firstName: userInfo.firstName,
        lastNameKana: userInfo.lastNameKana,
        firstNameKana: userInfo.firstNameKana,
        mailAddress: userInfo.mailAddress,
        phoneNumber: userInfo.phoneNumber,
        corpoName: userInfo.corpoName,
        corpoNameKana: userInfo.corpoNameKana,
        departmentName: userInfo.departmentName,
        postCode1: userInfo.postCode1,
        postCode2: userInfo.postCode2,
        address1: userInfo.address1,
      });

      if (result.status !== 200) {
        return null;
      }

      return result.data;
    } catch (err) {
      this.setAlertShowWarapper(true);
      console.error(err);
      return null;
    }
  };

  /**
   * 担当者連絡API
   * @param updateFlg
   * @param updateNumber
   * @param contactChangeFlg
   * @param userInfo
   * @returns
   */
  PutContactAddress = async (
    updateFlg: number,
    updateNumber: string,
    contactChangeFlg: boolean,
    userInfo: MyIF.userInfo
  ) => {
    try {
      const result = await new API.PutContactAddressControllerApi(
        this.config,
        '',
        this.axiosInstance
      ).putContactAddress({
        updateFlg: updateFlg,
        updateNumber: updateNumber,
        contactChangeFlg: contactChangeFlg,
        lastName: userInfo.lastName,
        firstName: userInfo.firstName,
        lastNameKana: userInfo.lastNameKana,
        firstNameKana: userInfo.firstNameKana,
        mailAddress: userInfo.mailAddress,
        phoneNumber: userInfo.phoneNumber,
        corpoName: userInfo.corpoName,
        corpoNameKana: userInfo.corpoNameKana,
        departmentName: userInfo.departmentName,
        postCode1: userInfo.postCode1,
        postCode2: userInfo.postCode2,
        address1: userInfo.address1,
      });

      if (result.status !== 200) {
        return null;
      }

      return true;
    } catch (err) {
      this.setAlertShowWarapper(true);
      console.error(err);
      return null;
    }
  };

  /**
   * tessellated.mpacパス取得
   * @param threeDDataID
   * @returns
   */
  GetThreeDModelPath = async (threeDDataID: string) => {
    try {
      const result = await new API.GetThreeDModelPathControllerApi(
        this.config,
        '',
        this.axiosInstance
      ).getThreeDModelPath({
        threeDDataID: threeDDataID,
      });

      if (result.status !== 200) {
        return null;
      }

      return { modelPath: result.data.modelMpacKey, bucket: result.data.bucketName };
    } catch (err) {
      this.setAlertShowWarapper(true);
      console.error(err);
      return null;
    }
  };

  /**
   * 試算削除API
   * @param trialNumberList
   */
  DeleteTrialNumber = async (trialNumberList: string[]) => {
    try {
      const result = await new API.DeleteTrialCalcControllerApi(
        this.config,
        '',
        this.axiosInstance
      ).deleteTrialCalc({
        specNumber: trialNumberList,
      });

      if (result.status !== 200) {
        return null;
      }

      return result;
    } catch (err) {
      this.setAlertShowWarapper(true);
      console.error(err);
      return null;
    }
  };

  /**
   * 型番チェックAPI
   * @param modelNumber
   * @returns
   */
  GetModelNumberCheck = async (modelNumber: string[]) => {
    try {
      const result = await new API.GetModelNumberCheckControllerApi(
        this.config,
        '',
        this.axiosInstance
      ).getModelNumberCheck({
        modelNumber: modelNumber,
      });

      if (result.status !== 200) {
        return null;
      }

      return result;
    } catch (err) {
      this.setAlertShowWarapper(true);
      console.error(err);
      return null;
    }
  };

  /**
   * 追加型番一覧取得API
   * @param modelingMaterialId
   * @param methodValue
   * @param printerValue
   * @param sortItem
   * @param sortBy
   * @param pageNumber
   * @param modelNumber
   * @returns
   */
  GetTrialCalcModelNumberAddList = async (
    modelingMaterialId: number,
    methodValue: string,
    printerValue: string,
    sortItem: string,
    sortBy: string,
    pageNumber: number,
    modelNumber: string[]
  ) => {
    try {
      const result = await new API.GetTrialCalcModelNumberAddListControllerApi(
        this.config,
        '',
        this.axiosInstance
      ).getTrialCalcModelNumberAddList({
        modelingMaterialId: modelingMaterialId,
        methodValue: methodValue,
        printerValue: printerValue,
        sortItem: sortItem,
        sortBy: sortBy,
        pageNumber: pageNumber,
        modelNumber: modelNumber,
      });

      if (result.status !== 200) {
        return null;
      }

      return result;
    } catch (err) {
      this.setAlertShowWarapper(true);
      console.error(err);
      return null;
    }
  };

  /**
   * 見積依頼（担当者）
   * @param quotationInfo
   * @returns
   */
  PutQuoteManual = async (quotationInfo: MyIF.quotationInfo) => {
    try {
      const result = await new API.PutQuoteManualControllerApi(
        this.config,
        '',
        this.axiosInstance
      ).putQuoteManual({ ...quotationInfo, ...quotationInfo.userInfo });

      if (result.status !== 200) {
        return null;
      }

      return true;
    } catch (err) {
      this.setAlertShowWarapper(true);
      console.error(err);
      return null;
    }
  };

  /**
   * 見積依頼
   * @param quotationInfo
   * @returns
   */
  PutQuoteAuto = async (quotationInfo: MyIF.quotationInfo) => {
    try {
      const result = await new API.PutQuoteControllerApi(
        this.config,
        '',
        this.axiosInstance
      ).putQuoteAuto({ ...quotationInfo, ...quotationInfo.userInfo });

      if (result.status !== 200) {
        return null;
      }

      return true;
    } catch (err) {
      this.setAlertShowWarapper(true);
      console.error(err);
      return null;
    }
  };

  /**
   * 顧客情報取得
   * @returns
   */
  GetUserContactAddress = async (trialNumber?: string[]) => {
    let userInfo: MyIF.userInfo;

    try {
      const result = await new API.GetUserContactAddressControllerApi(
        this.config,
        '',
        this.axiosInstance
      ).getUserContactAddress({
        trialCalcList: trialNumber || [],
      });

      if (result.status !== 200) {
        return null;
      }

      userInfo = {
        lastName: result.data.lastName,
        firstName: result.data.firstName,
        lastNameKana: result.data.lastNameKana,
        firstNameKana: result.data.firstNameKana,
        mailAddress: result.data.mailAddress,
        phoneNumber: result.data.phoneNumber,
        corpoName: result.data.corpoName,
        corpoNameKana: result.data.corpoNameKana,
        departmentName: result.data.departmentName,
        postCode1: result.data.postCode1,
        postCode2: result.data.postCode2,
        address1: result.data.address1,
      };

      return {
        userInfo: userInfo,
        mailFlg: result.data.mailFlg ? result.data.mailFlg : false,
        campaignCode: result.data.campaignCode,
      };
    } catch (err) {
      this.setAlertShowWarapper(true);
      console.error(err);
      return null;
    }
  };

  /**
   * 試算仕様 試算結果取得
   * @param specNumber
   * @param modelNumberList
   * @returns
   */
  GetTrialCalcResult = async (specNumber: string, modelNumberList: MyIF.addModelNumber[]) => {
    let priceInfo: MyIF.priceInfo;
    let boundingBoxInfo: MyIF.boundingBoxInfo = {
      batchList: [],
      basePlateX: 0,
      basePlateY: 0,
    };
    try {
      const result = await new API.GetTrialCalcResultControllerApi(
        this.config,
        '',
        this.axiosInstance
      ).getTrialCalcResult({
        specNumber: specNumber,
        modelNumberList: [
          ...modelNumberList.map((element) => {
            return {
              ...element,
            };
          }),
        ],
      });

      if (result.status !== 200) {
        return null;
      }

      priceInfo = {
        normal: {
          price: result.data.calcResult ? result.data.calcResult.normal.price : undefined,
          date: result.data.calcResult ? result.data.calcResult.normal.date : undefined,
        },
        express: {
          price: result.data.calcResult ? result.data.calcResult.express.price : undefined,
          date: result.data.calcResult ? result.data.calcResult.express.date : undefined,
        },
      };

      const loadBuffer = (blob: Blob): Promise<FileReader> => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(blob);
        return new Promise((resolve) => {
          reader.onload = () => resolve(reader);
        });
      };

      // モデルのmpacデータ取得
      const mpacList: { [modelNumber: string]: ArrayBuffer | undefined } = {};
      const rotateList: { [modelNumber: string]: MyIF.rotate | undefined } = {};
      for (let ii = 0; ii < result.data.modelMpacList.length; ii++) {
        const tmpMpacList = result.data.modelMpacList[ii];
        let modelData = undefined;
        if (tmpMpacList.modelMpacKey) {
          const blob = await new CachedS3ContentProvider().GetContent(
            tmpMpacList.bucketName,
            tmpMpacList.modelMpacKey
          );

          if (blob) {
            const reader = await loadBuffer(blob);
            const res = reader.result ? (reader.result as ArrayBuffer) : undefined;
            modelData = res;
          }
        }
        mpacList[tmpMpacList.modelNumber] = modelData;

        let rotate: MyIF.rotate | undefined;
        const rotateInfo = result.data.modelMpacList[ii].rotateInfo;
        if (rotateInfo) {
          rotate = {
            x: Number(rotateInfo.bank),
            y: Number(rotateInfo.attitude),
            z: Number(rotateInfo.heading),
          };
        }
        rotateList[tmpMpacList.modelNumber] = rotate;
      }

      result.data.batchList.forEach((batch) => {
        // 型番ごとに位置情報をまとめる
        let modelList: {
          modelNumber: string;
          size: {
            x: number;
            y: number;
            z: number;
          };
          coords: MyIF.coord[];
          modelData?: ArrayBuffer;
          rotate: MyIF.rotate | undefined;
        }[] = [];

        const coordList: { [modelNumber: string]: MyIF.coord[] } = {};
        batch.positionList.forEach((position) => {
          if (coordList[position.modelNumber] === undefined) coordList[position.modelNumber] = [];
          coordList[position.modelNumber].push({
            x: Number(position.positionX),
            y: Number(position.positionY),
            z: Number(position.positionZ),
            rot: position.rotated ? 90 * (Math.PI / 180) : 0,
          });
        });

        Object.keys(coordList).forEach((modelNumber) => {
          const size = {
            x: 0,
            y: 0,
            z: 0,
          };
          result.data.modelNumbers.map((ModelSizeInfoListDto) => {
            if (ModelSizeInfoListDto.modelNumber === modelNumber) {
              size.x = Number(ModelSizeInfoListDto.size.x);
              size.y = Number(ModelSizeInfoListDto.size.y);
              size.z = Number(ModelSizeInfoListDto.size.z);
            }
            return size;
          });

          modelList.push({
            modelNumber: modelNumber,
            size: size,
            coords: coordList[modelNumber],
            modelData: mpacList[modelNumber],
            rotate: rotateList[modelNumber],
          });
        });

        boundingBoxInfo.batchList.push({ modelList: modelList });
      });

      if (
        result.data.printerSizeInfoDto.maximumBuildSizeWidth &&
        result.data.printerSizeInfoDto.maximumBuildSizeDepth
      ) {
        boundingBoxInfo.basePlateX = result.data.printerSizeInfoDto.maximumBuildSizeWidth;
        boundingBoxInfo.basePlateY = result.data.printerSizeInfoDto.maximumBuildSizeDepth;
      }

      return { priceInfo, boundingBoxInfo, basePlateSize: result.data.printerSizeInfoDto };
    } catch (err) {
      this.setAlertShowWarapper(true);
      console.error(err);
      return null;
    }
  };

  /**
   * 試算仕様詳細取得API
   * @param trialNumber
   * @returns
   */
  GetTrialCalcDetail = async (trialNumber: string,  isReEdit?: boolean) => {
    let labelName: string = '';
    let modelNumberList: MyIF.addModelNumber[] = [];
    let priceInfo: MyIF.priceInfo;
    let deadLineInfo: MyIF.deadLineInfo;
    let selectModelCondition: MyIF.selectModelingConditionType;

    try {
      const result = await new API.GetTrialCalcDetailControllerApi(
        this.config,
        '',
        this.axiosInstance
      ).getTrialCalcDetail({
        specNumber: trialNumber,
        reEdit: isReEdit,
      });

      if (result.status !== 200) {
        return null;
      }

      // ラベル名
      labelName = result.data.labelName;

      // 追加型番
      result.data.modelNumberList.forEach((element) => {
        modelNumberList.push({
          modelNumber: element.modelNumber,
          label: element.labelName,
          num: element.num,
        });
      });

      selectModelCondition = {
        modelingMaterialId: result.data.modelingMaterialId
          ? result.data.modelingMaterialId
          : undefined,
        materialValue: result.data.materialValue ? result.data.materialValue : '',
        methodValue: result.data.methodValue ? result.data.methodValue : '',
        printerValue: result.data.printerValue ? result.data.printerValue : '',
      };

      // 金額
      priceInfo = result.data.calcResult
        ? result.data.calcResult
        : {
            normal: {
              price: undefined,
              date: undefined,
            },
            express: {
              price: undefined,
              date: undefined,
            },
          };

      deadLineInfo = {
        // prettier-ignore
        // eslint-disable-next-line
        deadLineDate: result.data.date ? result.data.date.replaceAll('/', '-') : '',
        price: result.data.price ? '￥' + result.data.price.toLocaleString() : '',
      };

      return {
        labelName,
        modelNumberList,
        selectModelCondition,
        priceInfo,
        deadLineInfo,
      };
    } catch (err) {
      this.setAlertShowWarapper(true);
      console.error(err);
      return null;
    }
  };

  /**
   * 休日リスト取得
   * @returns
   */
  GetHolidayList = async () => {
    try {
      const result = await new API.GetHolidayListControllerApi(
        this.config,
        '',
        this.axiosInstance
      ).getHoliday();

      if (result.status !== 200) {
        return null;
      }

      return result.data.holidayList;
    } catch (err) {
      this.setAlertShowWarapper(true);
      console.error(err);
      return null;
    }
  };

  /**
   * 試算仕様一覧取得
   * @param sortItem
   * @param sortBy
   * @param pageNumber
   * @param filterItem
   * @param specNumberList
   * @param searchValue
   * @returns
   */
  GetTrialCalcList = async (
    sortItem: string,
    sortBy: string,
    pageNumber: number,
    filterItem: string,
    searchValue?: string,
    specNumberList?: string[]
  ) => {
    try {
      const result = await new API.GetTrialCalcListControllerApi(
        this.config,
        '',
        this.axiosInstance
      ).getTrialCalcList({
        sortItem: sortItem,
        sortBy: sortBy,
        pageNumber: pageNumber,
        filterItem: filterItem,
        searchValue: searchValue,
        specNumberList: specNumberList,
      });

      if (result.status !== 200) {
        return null;
      }

      let outTrialNumberList: MyIF.trialNumberList[] = [];
      for (let ii = 0; ii < result.data.dataList.length; ii++) {
        const getData = result.data.dataList[ii];

        // 画像を取得
        const resultURL = await new FileController().GetPresignedURL(
          result.data.bucketName,
          getData.image
        );
        const imageURL = resultURL ? resultURL : '';

        outTrialNumberList.push({
          ...getData,
          image: imageURL,
          price: getData.price,
          date: getData.date ? getData.date : '-',
        });
      }
      return {
        trialNumberList: outTrialNumberList,
        ...result.data,
      };
    } catch (err) {
      this.setAlertShowWarapper(true);
      console.error(err);
      return null;
    }
  };

  /**
   * 造形条件取得
   * @returns
   */
  GetModelNumberMaterialList = async () => {
    try {
      const result = await new API.GetModelNumberMaterialListControllerApi(
        this.config,
        '',
        this.axiosInstance
      ).getModelNumberMaterialList();

      if (result.status !== 200) {
        return null;
      }

      let materialType: MyIF.materialType[] = [];
      result.data.materialList.forEach((element) => {
        let materialValue = element.value;
        let methodList: {
          methodValue: string;
          printerList: MyIF.threeDPrinter[];
        }[] = [];
        element.methodList.forEach((element1) => {
          methodList.push({
            methodValue: element1.value,
            printerList: element1.printerList,
          });
        });
        materialType.push({
          modelingMaterialId: element.modelingMaterialId,
          materialValue: materialValue,
          sozaiValue: element.materialName,
          methodList: methodList,
          materialImage: element.materialImage,
          materialMemo: element.materialMemo,
        });
      });
      return materialType;
    } catch (err) {
      this.setAlertShowWarapper(true);
      console.error(err);
      return null;
    }
  };

  /**
   * 材料比較結果取得
   * @param threeDDataID
   * @param printerValue
   * @returns
   */
  GetMaterialPrice = async (threeDDataID: string, printerValue: string) => {
    try {
      const result = await new API.GetMaterialPriceControllerApi(
        this.config,
        '',
        this.axiosInstance
      ).getMaterialPrice({
        threeDDataID,
        printerValue,
      });

      if (result.status !== 200) {
        return null;
      }

      let materialPriceList: MyIF.materialPrice[] = [];
      result.data.calcResultList.forEach((element) => {
        materialPriceList.push({
          ...element,
          materialSubTitle: '',
        });
      });

      return materialPriceList;
    } catch (err) {
      this.setAlertShowWarapper(true);
      console.error(err);
      return null;
    }
  };

  /**
   * 型番削除API
   * @param modelNumberList
   */
  DeleteModelNumber = async (modelNumberList: string[]) => {
    try {
      const result = await new API.DeleteModelNumberControllerApi(
        this.config,
        '',
        this.axiosInstance
      ).deleteModelNumber({
        modelNumber: modelNumberList,
      });

      if (result.status !== 200) {
        return null;
      }

      return result.data.checkResult;
    } catch (err) {
      this.setAlertShowWarapper(true);
      console.error(err);
      return null;
    }
  };

  /**
   * 型番一覧取得API
   * @param sortItem
   * @param sortBy
   * @param pageNumber
   * @param filterItem
   * @param searchValue
   */
  GetModelNumberList = async (
    sortItem: string,
    sortBy: string,
    pageNumber: number,
    filterItem: string,
    searchValue?: string,
    modelNumberList?: string[]
  ) => {
    try {
      const result = await new API.ModelNumberListControllerApi(
        this.config,
        '',
        this.axiosInstance
      ).getModelNumberList({
        sortItem: sortItem,
        sortBy: sortBy,
        pageNumber: pageNumber,
        filterItem: filterItem,
        searchValue: searchValue,
        modelNumberList: modelNumberList,
      });

      if (result.status !== 200) {
        return null;
      }

      let outModelNumberList: MyIF.modelNumberList[] = [];
      for (let ii = 0; ii < result.data.dataList.length; ii++) {
        const getData = result.data.dataList[ii];

        // 画像を取得
        const resultURL = await new FileController().GetPresignedURL(
          result.data.bucketName,
          getData.image
        );
        const imageURL = resultURL ? resultURL : '';

        outModelNumberList.push({
          image: imageURL,
          fileName: getData.fileName,
          labelName: getData.labelName,
          modelNumber: getData.modelNumber,
          materialName: getData.materialName ? getData.materialName : '-',
          modelName: getData.modelName ? getData.modelName : '-',
          price: getData.price,
          date: getData.date ? getData.date + '営業日' : '-',
          status: getData.status,
          updateDate: getData.updateDate,
          optionStressRelief: getData.optionStressRelief ? getData.optionStressRelief : '-',
        });
      }

      return {
        modelNumberList: outModelNumberList,
        ...result.data,
      };
    } catch (err) {
      this.setAlertShowWarapper(true);
      console.error(err);
      return null;
    }
  };

  /**
   * オプション項目取得API
   * @param modelingMaterialId
   * @returns
   */
  GetModelNumberOption = async (modelingMaterialId: number) => {
    let response: MyIF.optionItem[] = [];

    try {
      const result = await new API.GetModelNumberOptionControllerApi(
        this.config,
        '',
        this.axiosInstance
      ).getModelNumberOption({
        modelingMaterialId,
      });

      if (result.status !== 200) {
        return null;
      }

      for (let ii = 0; ii < result.data.optionList.length; ii++) {
        response.push({
          labelName: result.data.optionList[ii].labelName,
          exp: result.data.optionList[ii].exp,
          maxNum: result.data.optionList[ii].maxNum,
          items: result.data.optionList[ii].items,
          perType: result.data.optionList[ii].selectType as MyIF.optionPerType,
        });
      }
      return response;
    } catch (err) {
      this.setAlertShowWarapper(true);
      console.error(err);
      return null;
    }
  };

  /**
   * 単価の試算詳細取得API
   * 単価の試算のコピーして編集の詳細取得API
   * @param modelNumber
   * @returns
   */
  GetModelNumberDetail = async (modelNumber: string, isReEdit?: boolean) => {
    let labelName: string = '';
    let threeDFileName: string = '';
    let threeDFileSize: number = 0;
    let modelConditon: MyIF.selectModelingConditionType;
    let optionInfo: MyIF.optionInfo;
    let priceInfo: MyIF.priceInfo;
    let viewerMemo: string = '';
    let requireCalcFlg: boolean = false;
    let modelViewInfo: MyIF.modelViewInfo = {
      id: '',
      supportMpacKey: '',
      modelMpacKey: '',
      bucketName: '',
      rotate: { x: 0, y: 0, z: 0 },
      image: '',
    };

    try {
      const result = await new API.GetModelNumberDetailControllerApi(
        this.config,
        '',
        this.axiosInstance
      ).getModelNumberDetail({
        modelNumber: modelNumber,
        reEdit: isReEdit,
      });

      if (result.status !== 200) {
        return null;
      }

      // ラベル名
      labelName = result.data.labelName;

      // 3Dモデル
      threeDFileName = result.data.fileName;

      // 3Dファイルサイズ
      threeDFileSize = result.data.fileSize;

      // 造形条件　選択値
      modelConditon = {
        modelingMaterialId: result.data.modelingMaterialId
          ? result.data.modelingMaterialId
          : undefined,
        materialValue: result.data.materialValue ? result.data.materialValue : '',
        methodValue: result.data.methodValue ? result.data.methodValue : '',
        printerValue: result.data.printerValue ? result.data.printerValue : '',
      };

      // オプション選択値
      // ラベルごとに整える
      let tmpOptionList: { [key: string]: [{ value: string; num: string }] } = {};
      for (let ii = 0; ii < result.data.optionList.length; ii++) {
        let tmpDto: API.OptionDto = result.data.optionList[ii];
        if (!(tmpDto.labelName in tmpOptionList)) {
          tmpOptionList[tmpDto.labelName] = [
            {
              value: tmpDto.value,
              num: tmpDto.num ? tmpDto.num : '',
            },
          ];
        } else {
          tmpOptionList[tmpDto.labelName].push({
            value: tmpDto.value,
            num: tmpDto.num ? tmpDto.num : '',
          });
        }
      }

      let tmpOptionSelectList: MyIF.optionSelect[] = [];
      Object.keys(tmpOptionList).forEach((labelName) => {
        tmpOptionSelectList.push({
          labelName: labelName,
          select: tmpOptionList[labelName],
        });
      });

      optionInfo = {
        optionSelectList: tmpOptionSelectList,
        // 指示書フラグ True:OFF　False:ON
        dirFlg: !result.data.directions,
        dirComment: result.data.directionsComment ? result.data.directionsComment : '',
        dirFileName: result.data.directionsFile,
      };

      // 金額
      priceInfo = {
        normal: {
          price: result.data.calcResult ? result.data.calcResult.normal.price : undefined,
          date: result.data.calcResult ? result.data.calcResult.normal.date : '',
        },
        express: {
          price: result.data.calcResult ? result.data.calcResult.express.price : undefined,
          date: result.data.calcResult ? result.data.calcResult.express.date : '',
        },
      };

      // ビューワーメモ
      viewerMemo = result.data.memo ? result.data.memo : '';

      // 計算フラグ
      requireCalcFlg = result.data.requireCalcFlg;

      // モデル情報
      modelViewInfo.id = result.data.threeDDataID;
      modelViewInfo.supportMpacKey = result.data.supportModelMpacKey;
      modelViewInfo.modelMpacKey = result.data.modelMpacKey ? result.data.modelMpacKey : '';
      modelViewInfo.bucketName = result.data.bucketName;
      if (result.data.rotateInfo) {
        modelViewInfo.rotate = {
          x: Number(result.data.rotateInfo.bank),
          y: Number(result.data.rotateInfo.attitude),
          z: Number(result.data.rotateInfo.heading),
        };
      } else {
        modelViewInfo.rotate = {
          x: 0,
          y: 0,
          z: 0,
        };
      }
      if (modelViewInfo.modelMpacKey !== '') {
        modelViewInfo.modelBlob = await new CachedS3ContentProvider().GetContent(
          modelViewInfo.bucketName,
          modelViewInfo.modelMpacKey
        );
      }
      if (modelViewInfo.supportMpacKey) {
        modelViewInfo.supportBlob = await new CachedS3ContentProvider().GetContent(
          modelViewInfo.bucketName,
          modelViewInfo.supportMpacKey
        );
      }

      if (result.data.onPartsSupportModelMpacKey) {
        modelViewInfo.onPartsSupportBlob = await new CachedS3ContentProvider().GetContent(
          modelViewInfo.bucketName,
          result.data.onPartsSupportModelMpacKey
        );
      }

      if (result.data.crossSectionalNgModelKey) {
        modelViewInfo.crossSectionalNgBlob = await new CachedS3ContentProvider().GetContent(
          modelViewInfo.bucketName,
          result.data.crossSectionalNgModelKey
        );
      }

      // Image取得      // 画像を取得
      const resultURL = await new FileController().GetPresignedURL(
        result.data.imageBucketName,
        result.data.imageKey
      );
      modelViewInfo.image = resultURL ? resultURL : '';
      console.log('image:', modelViewInfo.image);
      return {
        labelName,
        threeDFileName,
        threeDFileSize,
        modelConditon,
        optionInfo,
        priceInfo,
        viewerMemo,
        requireCalcFlg,
        modelViewInfo,
      };
    } catch (err) {
      this.setAlertShowWarapper(true);
      console.error(err);
      return null;
    }
  };

  /**
   * 造形可否判定結果取得API
   * @param threeDDataID
   * @returns
   */
  GetModelingResult = async (threeDDataID: string) => {
    let isJudge: number = 1;
    let modelingResult: MyIF.modelingResultType[] = [];
    let trialCalcInfo: MyIF.trialCalcInfoType | undefined = undefined;
    try {
      const result = await new API.GetThreeDModelCalcResultControllerApi(
        this.config,
        '',
        this.axiosInstance
      ).getThreeDModelCalcResult({
        threeDDataID: threeDDataID,
      });

      if (result.status !== 200) {
        return null;
      }

      for (let ii = 0; ii < result.data.materialResult.length; ii++) {
        modelingResult.push({ ...result.data.materialResult[ii] });
        if (result.data.materialResult[ii].judge === 2) {
          if (isJudge !== 3) {
            isJudge = result.data.materialResult[ii].judge;
          }
        }
        if (result.data.materialResult[ii].judge === 3) {
          isJudge = result.data.materialResult[ii].judge;
        }
      }

      trialCalcInfo = result.data.calcInfo ? { ...result.data.calcInfo } : undefined;

      if (result.data.status === 2) {
        // エラーにする
        throw '[Error] getThreeDModelCalcResult: status 2';
      }

      let supportBlob = undefined;
      if (result.data.supportModelMpacKey && result.data.bucketName) {
        supportBlob = await new CachedS3ContentProvider().GetContent(
          result.data.bucketName,
          result.data.supportModelMpacKey
        );
      }

      let onPartsSupportBlob = undefined;
      if (result.data.onPartsSupportModelMpacKey && result.data.bucketName) {
        onPartsSupportBlob = await new CachedS3ContentProvider().GetContent(
          result.data.bucketName,
          result.data.onPartsSupportModelMpacKey
        );
      }

      let crossSectionalNgBlob = undefined;
      if (result.data.crossSectionalNgModelKey && result.data.bucketName) {
        crossSectionalNgBlob = await new CachedS3ContentProvider().GetContent(
          result.data.bucketName,
          result.data.crossSectionalNgModelKey
        );
      }

      return {
        status: result.data.status,
        threeDDataId: undefined,
        isJudge,
        modelingResult,
        trialCalcInfo,
        supportModel: {
          key: result.data.supportModelMpacKey,
          bucket: result.data.bucketName,
          blob: supportBlob,
        },
        onPartsSupportBlob,
        crossSectionalNgBlob,
        rotateInfo: undefined,
      };
    } catch (err) {
      this.setAlertShowWarapper(true);
      console.error(err);
      return null;
    }
  };

  /**
   * 造形可否判定結果取得API
   * @param threeDDataID
   * @returns
   */
  GetRecommendModelingResult = async (groupID: string) => {
    let isJudge: number = 1;
    let modelingResult: MyIF.modelingResultType[] = [];
    let trialCalcInfo: MyIF.trialCalcInfoType | undefined = undefined;
    try {
      const result = await new API.GetThreeDModelRecommendCalcResultControllerApi(
        this.config,
        '',
        this.axiosInstance
      ).getThreeDModelRecommendCalcResult({
        groupID: groupID,
      });

      if (result.status !== 200) {
        return null;
      }

      for (let ii = 0; ii < result.data.materialResult.length; ii++) {
        modelingResult.push({ ...result.data.materialResult[ii] });
        if (result.data.materialResult[ii].judge === 2) {
          if (isJudge !== 3) {
            isJudge = result.data.materialResult[ii].judge;
          }
        }
        if (result.data.materialResult[ii].judge === 3) {
          isJudge = result.data.materialResult[ii].judge;
        }
      }

      trialCalcInfo = result.data.calcInfo ? { ...result.data.calcInfo } : undefined;

      if (result.data.status === 2) {
        // エラーにする
        throw '[Error] getThreeDModelCalcResult: status 2';
      }

      let supportBlob = undefined;
      if (result.data.supportModelMpacKey && result.data.bucketName) {
        supportBlob = await new CachedS3ContentProvider().GetContent(
          result.data.bucketName,
          result.data.supportModelMpacKey
        );
      }

      let onPartsSupportBlob = undefined;
      if (result.data.onPartsSupportModelMpacKey && result.data.bucketName) {
        onPartsSupportBlob = await new CachedS3ContentProvider().GetContent(
          result.data.bucketName,
          result.data.onPartsSupportModelMpacKey
        );
      }

      let crossSectionalNgBlob = undefined;
      if (result.data.crossSectionalNgModelKey && result.data.bucketName) {
        crossSectionalNgBlob = await new CachedS3ContentProvider().GetContent(
          result.data.bucketName,
          result.data.crossSectionalNgModelKey
        );
      }

      return {
        status: result.data.status,
        threeDDataId: result.data.threeDDataId,
        isJudge,
        modelingResult,
        trialCalcInfo,
        supportModel: {
          key: result.data.supportModelMpacKey,
          bucket: result.data.bucketName,
          blob: supportBlob,
        },
        onPartsSupportBlob,
        crossSectionalNgBlob,
        rotateInfo: result.data.rotateInfo,
      };
    } catch (err) {
      this.setAlertShowWarapper(true);
      console.error(err);
      return null;
    }
  };

  /**
   * アップロード完了通知用
   * @param threeDDataID
   * @returns
   */
  PostThreeDDataUploadFinished = async (threeDDataID: string) => {
    try {
      const result = await new API.PostThreeDDataUploadFinishedControllerApi(
        this.config,
        '',
        this.axiosInstance
      ).postThreeDDataUploadFinished({
        threeDDataID: threeDDataID,
      });

      if (result.status !== 200) {
        return null;
      }

      return true;
    } catch (err) {
      this.setAlertShowWarapper(true);
      console.error(err);
      return null;
    }
  };

  /**
   * ユーザー登録チェック用
   * @param registInfo
   * @returns
   */
  GetUserRegistCheck = async (registInfo: MyIF.userRegistInfo) => {
    try {
      const result = await new API.GetUserInputCheckControllerApi(
        this.config,
        '',
        this.axiosInstance
      ).getUserInputCheck({
        ...registInfo.userInfo,
        password1: registInfo.password1 ? registInfo.password1 : '',
        password2: registInfo.password2 ? registInfo.password2 : '',
        policyFlg: registInfo.policyFlg === undefined ? false : registInfo.policyFlg,
        mailFlg: registInfo.mailFlg === undefined ? false : registInfo.mailFlg,
      });

      if (result.status !== 200) {
        return null;
      }

      return result.data.checkResult;
    } catch (err) {
      this.setAlertShowWarapper(true);
      console.error(err);
      return null;
    }
  };

  /**
   * ユーザー登録
   * @param registInfo
   * @returns
   */
  PutUserRegist = async (registInfo: MyIF.userRegistInfo) => {
    try {
      const result = await new API.PutUserControllerApi(
        this.config,
        '',
        this.axiosInstance
      ).putUser({
        ...registInfo.userInfo,
        password1: registInfo.password1 ? registInfo.password1 : '',
        password2: registInfo.password2 ? registInfo.password2 : '',
        policyFlg: registInfo.policyFlg === undefined ? false : registInfo.policyFlg,
        mailFlg: registInfo.mailFlg === undefined ? false : registInfo.mailFlg,
      });

      if (result.status !== 200) {
        return null;
      }

      return result.data.userId;
    } catch (err) {
      this.setAlertShowWarapper(true);
      console.error(err);
      return null;
    }
  };

  /**
   * ユーザー登録 send email
   * @returns
   * @param userId
   */
  PutUserConfirm = async (userId: number) => {
    try {
      const result = await new API.PutUserConfirmControllerApi(
        this.config,
        '',
        this.axiosInstance
      ).putUserConfirm({ id: userId });

      if (result.status !== 200) {
        return null;
      }

      return true;
    } catch (err) {
      this.setAlertShowWarapper(true);
      console.error(err);
      return null;
    }
  };

  /**
   * ワンタイムパスワード発行
   * @param lastName
   * @param firstName
   * @param mailAddress
   * @returns
   */
  PutOneTimePassword = async (lastName: string, firstName: string, mailAddress: string) => {
    try {
      const result = await new API.PutOneTimePwControllerApi(
        this.config,
        '',
        this.axiosInstance
      ).putOneTimePW({
        lastName,
        firstName,
        mailAddress,
      });

      if (result.status !== 200) {
        return null;
      }

      return result.data.checkResult;
    } catch (err) {
      this.setAlertShowWarapper(true);
      console.error(err);
      return null;
    }
  };

  /**
   * ユーザー情報更新
   * @param registInfo
   * @returns
   */
  PutUserInfo = async (registInfo: MyIF.userRegistInfo) => {
    try {
      const result = await new API.PutUserInfoControllerApi(
        this.config,
        '',
        this.axiosInstance
      ).putUserInfo({
        ...registInfo.userInfo,
        mailFlg: registInfo.mailFlg === undefined ? false : registInfo.mailFlg,
      });

      if (result.status !== 200) {
        return null;
      }

      return result.data.checkResult;
    } catch (err) {
      this.setAlertShowWarapper(true);
      console.error(err);
      return null;
    }
  };

  /**
   * チャット用ハッシュ値取得
   * @returns
   */
  GetChatHash = async () => {
    try {
      const result = await new API.GetChatIdentityHashControllerApi(
        this.config,
        '',
        this.axiosInstance
      ).getChatIdentityHash();

      return result.data.hash;
    } catch (err) {
      this.setAlertShowWarapper(true);
      console.error(err);
      return null;
    }
  };

  /**
   * メールアドレス更新通知
   * @returns
   */
  PutMailAddress = async () => {
    try {
      const result = await new API.PutMailAddressChangedControllerApi(
        this.config,
        '',
        this.axiosInstance
      ).putMailAddressChanged();

      if (result.status !== 200) {
        return null;
      }

      return true;
    } catch (err) {
      this.setAlertShowWarapper(true);
      console.error(err);
      return null;
    }
  };

  /**
   * 試算表PDF取得
   * @returns
   */
  GetTrialCalcSpecPdf = async (specNumber: string) => {
    try {
      const result = await new API.GetTrialCalcSpecPdfControllerApi(
        this.config,
        '',
        this.axiosInstance
      ).getTrialCalcSpecPdf(specNumber, { responseType: 'blob' });

      if (result.status !== 200) {
        return null;
      }

      return result.data;
    } catch (err) {
      this.setAlertShowWarapper(true);
      console.error(err);
      return null;
    }
  };

  /**
   * 見積書PDF取得
   * @returns
   */
  GetQuotePdf = async (specNumber: string) => {
    try {
      const result = await new API.GetQuotePdfControllerApi(
        this.config,
        '',
        this.axiosInstance
      ).getPdf({ specNumber });

      if (result.status !== 200) {
        return null;
      }

      let pdf = undefined;
      pdf = await new FileController().FileDownloadS3(result.data.bucketName, result.data.fileKey);

      return pdf;
    } catch (err) {
      this.setAlertShowWarapper(true);
      console.error(err);
      return null;
    }
  };

  /**
   * federatedId登録
   * @param federatedId
   * @returns
   */
  PutFederatedId = async (federatedId: string) => {
    try {
      const result = await new API.PutFederatedIdControllerApi(
        this.config,
        '',
        this.axiosInstance
      ).putFederatedId({ federatedId });

      if (result.status !== 200) {
        return null;
      }
      return true;
    } catch (err) {
      this.setAlertShowWarapper(true);
      console.error(err);
      return null;
    }
  };
}
